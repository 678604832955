import React, { useState, useEffect, useContext } from 'react';
import './subscription.scss';
import EquipmentCard from './equipment-card/EquipmentCard';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../../dashboard/common-components/buttons/Button';
import { getEquipmentData, GetOwnerEquipments } from '../../../service/equipmentService';
import { addNewUserPurchasedSubscription } from '../../../service/subscriptionService';
import { toast } from 'sonner';

import { load } from '@cashfreepayments/cashfree-js';
import { LoadingContext } from '../../../context/LoadingContext';

import { initiatePaymentSession, verifyPaymentService } from '../../../service/paymentService';
import UserContext from '../../../context/userContext';

function SubscriptionPlan() {
  const navigate = useNavigate();
  const location = useLocation();

  const context = useContext(UserContext);
  const { user } = context;

  const {showLoader, hideLoader} = useContext(LoadingContext);
  const { plan } = location.state || {};
  const [equipments, setEquipments] = useState([]);
  const [selectedEquipments, setSelectedEquipments] = useState([]);
  const [paymentMode, setPaymentMode] = useState(1);
  const [subscriptionPeriod, setSubscriptionPeriod] = useState(1);
  const [orderId, setOrderId] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [verificationResponse, setVerificationResponse] = useState([])

  let cashfree;

  console.log(user)

  useEffect(() => {
    if (paymentSuccessful) {
      handleSubmit();
    }
  }, [paymentSuccessful]);

  useEffect(() => {
    fetchEquipments();
  }, []);

  const fetchEquipments = async () => {
    showLoader();
    try {
      const response = await GetOwnerEquipments({
        supplierId : user.loggedInUserId
      });;
      const data = response.data;
      setEquipments(data);
      hideLoader();
    } catch (error) {

      console.error("Error fetching equipment data:", error);
      hideLoader();
    }
  };

  const handleSelect = (id) => {
    if (selectedEquipments.includes(id)) {
      setSelectedEquipments(selectedEquipments.filter(equipId => equipId !== id));
    } else if (selectedEquipments.length < plan.equipmentCount) {
      setSelectedEquipments([...selectedEquipments, id]);
    }
  };

  const handleSubmit = async () => {
    const currentDate = new Date().toISOString();
    console.log(verificationResponse)

    const payload = {
      addedBy: user.loggedInUserId,
      addedOn: currentDate,
      updatedBy: user.loggedInUserId,
      updatedOn: currentDate,
      subscriptionId: plan.id,
      purchaseDate: currentDate,
      startDate: currentDate,
      endDate: new Date(new Date().setMonth(new Date().getMonth() + subscriptionPeriod)).toISOString(),
      price: plan.price,
      discount: plan.discount || 0,
      finalPrice: plan.discountPrice || plan.price,
      paymentMode,
      userId: user.loggedInUserId,
      subscriptionPeriod,
      active: true,
      deleted: false,
      selectedEquipmentIds: selectedEquipments,
      paymentId: verificationResponse[0].cf_payment_id,

      payment: {
        orderId,
        gatewayOrderId: verificationResponse[0].cf_payment_id,
        gatewayPaymentId: verificationResponse[0].cf_payment_id,
        orderAmount: verificationResponse[0].order_amount,
        paymentAmount: verificationResponse[0].payment_amount,
        paymentCompletionTime: verificationResponse[0].payment_completion_time,
        gatewayStatusCode: verificationResponse[0].payment_status,
        paymentGroup: verificationResponse[0].payment_group,
        paymentMessage: verificationResponse[0].payment_status,
        paymentStatus: verificationResponse[0].payment_status
      }
    };

    try {
      const response = await addNewUserPurchasedSubscription(payload);

      if (response === "Subscription purchased successfully!") {
        toast.success('Subscription purchased successfully!');
        navigate('/dashboard/subscription-admin');
      } else {
        toast.error('Failed to purchase subscription. Please try again.');
      }
    } catch (error) {
      console.error('Error during subscription purchase:', error);
      toast.error('An error occurred while processing your request. Please try again later.');
    }
  };



  const initializeSDK = async () => {
    try {


      const observer = new MutationObserver(() => {
        const modal = document.getElementById('cashfree-modal-container');
        if (modal) {
          modal.style.position = 'fixed';
          modal.style.top = '0';
          modal.style.left = '0';
          modal.style.bottom = '0';
          modal.style.right = '0';
          modal.style.margin = 'auto';
          modal.style.transform = 'none';
          modal.style.overflow = 'hidden';
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });

      return () => observer.disconnect();
    } catch (err) {
      console.error('Failed to load Cashfree SDK:', err);
      toast.error('Error initializing payment gateway.');
    }
  };

  initializeSDK();





  const getSessionId = async () => {
    setLoading(true);
    try {
        const { sessionId, orderId: newOrderId } = await initiatePaymentSession(plan, {
            id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            name: user.loggedUserName,
            email: user.name,
            phone: user.loggedInUserPhonenumber
        });

        setOrderId(newOrderId);
        console.log(orderId)
        localStorage.setItem("orderId", newOrderId);

        console.log("this is session id",sessionId)
        return sessionId;
    } catch (error) {
        setError("Failed to initiate payment. Please try again.");
        toast.error("Failed to get payment session.");
    } finally {
        setLoading(false);
    }
};

  const handlePayment = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const cashfreeMode = process.env.REACT_APP_CASHFREE_MODE || 'sandbox';

      
      const cashfree = await load({ mode: cashfreeMode });
        // cashfree = await load({ mode: 'production' });


        const sessionId = await getSessionId();
        if (!sessionId) return;
        // console.log(first)

        const checkoutOptions = {
            paymentSessionId: sessionId,
            redirectTarget: '_modal',
        };

        cashfree.checkout(checkoutOptions).then((res) => {
            if (res.paymentDetails) {
                verifyPayment();
            } else {
                throw new Error('Payment details not available. Please try again.');
            }
        }).catch((err) => {
            console.error('Error initiating payment:', err);
            toast.error('Payment initiation failed. Please check your details and try again.');
        });
    } catch (err) {
        console.error('Payment handling error:', err);
        setError('There was a problem processing your payment. Please try again.');
        toast.error('Payment failed. Please try again later.');
    } finally {
        setLoading(false);
    }
};

const verifyPayment = async () => {
  setLoading(true);
  try {
      const currentOrderId = orderId || localStorage.getItem("orderId");
      if (!currentOrderId) throw new Error("Order ID missing");

      const verificationResponseData = await verifyPaymentService(currentOrderId);
      setVerificationResponse(verificationResponseData);
      toast.success("Payment successful!");
      setPaymentSuccessful(true);
  } catch (error) {
      setError("Payment verification failed.");
  } finally {
      setLoading(false);
  }
};

const getRentalType = (type) => {
  switch (type) {
    case 0: return 'Hours';
    case 1: return 'Day';
    case 2: return 'Week';
    case 3: return 'Month';
    default: return '';
  }
};



  return (
    <div className='subscription-plan-container'>
      <h3>SELECT PRODUCTS</h3>
      <div className='details'>
        <h3>SUBSCRIPTION DETAILS</h3>

        <h4 className='plan-name'>{plan.title}</h4>
        <div className='row'>
          <span className='discount-span'>YOU SAVED {plan.discount}</span>
          <p className='discount-price'>₹ {plan.discountPrice}</p>
          <div className='price'>₹ {plan.price} <span>{plan.priceUnit}</span></div>
        </div>
        <div className='row perks'>
          {plan.features.map((feature, index) => (
            <p key={index}>* {feature}</p>
          ))}
        </div>
        <div className='row'>
          <h4 className='plan-name'>Equipment Listed <span className='list-count'>{selectedEquipments.length}/{plan.equipmentCount}</span></h4>
        </div>
      </div>
      <div className='action-btn'>

        <Button
            type='submit'
            className='save-btn'
            tooltip="Please select at least one equipment to proceed with the purchase"
            disabled={selectedEquipments.length < 1}
            label='Buy'
            onClick={handlePayment}/>
      </div>
      <div className='equipments-list'>
        {equipments.map(equipment => (
          <EquipmentCard
            key={equipment.id}
            img={equipment.image1Url}
            name={equipment.name}
            rent={equipment.finalRentalValue}
            rentalType={equipment.rentalType}
            isSelected={selectedEquipments.includes(equipment.id)}
            onSelect={() => handleSelect(equipment.id)}
            ratings={equipment.equipmentRatings}
            disabled={selectedEquipments.length >= plan.equipmentCount && !selectedEquipments.includes(equipment.id)}
          />
        ))}
      </div>


    </div>
  );
}

export default SubscriptionPlan;
