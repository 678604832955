import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import './location.scss';
import { CiSearch } from 'react-icons/ci';
import { LoadScript, GoogleMap, MarkerF, InfoWindowF } from '@react-google-maps/api';
import { IoCaretDownSharp, IoCaretUpSharp } from 'react-icons/io5';
import locationIcon from './location-pin-2965.png';
import { GetActiveSubscriptionEquipments, getEquipmentData } from '../../../service/equipmentService';
import { FaStar } from 'react-icons/fa';
import { LuStar } from 'react-icons/lu';
import { CgClose } from 'react-icons/cg';
import { useCity } from '../../../context/LocationContext';
import { Link } from 'react-router-dom';
import cities from '../../../Constants/Cities';
import { LoadingContext } from '../../../context/LoadingContext';

const libraries = ['places'];

const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

const zoom = 11;

// const defaultAddress = 'koregaon park';

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

function Location() {
  const [map, setMap] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [address, setAddress] = useState();
  const [coordinates, setCoordinates] = useState(null);
  const [equipmentsData, setEquipmentsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 18.49183065256629, lng: 73.81959673945367 });
  const { selectedNavCity } = useCity();
  const {showLoader, hideLoader} = useContext(LoadingContext);

  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // useEffect(() => {
  //   navigator.geolocation.getCurrentPosition(
  //     (position) => {
  //       const { latitude, longitude } = position.coords;
  //       setMapCenter({ lat: latitude, lng: longitude });
  //       getAddressFromCoordinates(latitude, longitude);
  //     },
  //     (error) => {
  //       console.error('Error fetching geolocation:', error);
  //     }
  //   );
  // }, []);

  const getAddressFromCoordinates = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyB005xJuSALE7oAIxsJj0-AbW4orNeDKts`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        setAddress(data.results[0].formatted_address);
      }
    } catch (error) {
      console.error('Error fetching address from coordinates:', error);
    }
  };

  useEffect(() => {
    fetchEquipmentsLocation();
  }, []);

  useEffect(() => {
    // Simulate delay to ensure the script is properly initialized
    const timer = setTimeout(() => {
      setIsScriptLoaded(true);
    }, 2000);

    return () => clearTimeout(timer); // Clean up timeout
  }, []);

  useEffect(() => {
    if (selectedNavCity && selectedNavCity.name) {
      handleSelect(selectedNavCity.name);
    }
  }, [selectedNavCity]);


  const calculateAverageRating = (ratings) => {
    if (!ratings || ratings.length === 0) return 0;
    const totalRating = ratings.reduce((acc, rating) => acc + rating.starRating, 0);
    return totalRating / ratings.length;
  };

  const renderStars = (rating) => {
    const totalStars = 5;
    const validRating = Math.round(rating);
    const filledStars = Array(validRating).fill(<FaStar size={14} className="filled-star" />);
    const emptyStars = Array(totalStars - validRating).fill(<LuStar size={14} className="empty-star" />);
    return [...filledStars, ...emptyStars];
  };

  const handleSelect = async (option) => {
    setSelectedOption(option);
    setIsOpen(false);

    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(option)}&key=AIzaSyB005xJuSALE7oAIxsJj0-AbW4orNeDKts`
      );
      const data = await response.json();

      if (data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        setMapCenter({ lat, lng });

      } else {
        console.error('No results found for the selected city.');
      }
    } catch (error) {
      console.error('Error fetching city coordinates:', error);
    }
  };


  const handleInputChange = (e) => {
    setAddress(e.target.value);
  };

  const fetchEquipmentsLocation = async () => {

    try {
      const response = await GetActiveSubscriptionEquipments({
        searchTerm: "",
        startRentprice: 0,
        endRentprice: 0,
        categoryId: '',
       // insuranceAvailable: true,
      });;
      console.log("API Response:", response);
      const data = response;
      setEquipmentsData(data);

    } catch (error) {
      console.error("Error fetching equipment data:", error);
      setError(error.message);
    } finally {

    }
  };

  const handleSearch = async () => {
    if (address) {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyB005xJuSALE7oAIxsJj0-AbW4orNeDKts`
        );
        const data = await response.json();
        if (data.results.length > 0) {
          const { lat, lng } = data.results[0].geometry.location;
          setCoordinates({ lat, lng });
          setMapCenter({ lat, lng });
          if (map) {
            map.setCenter({ lat, lng });
          }
        } else {
          console.error('No results found for the address.');
        }
      } catch (error) {
        console.error('Error fetching geocode data:', error);
      }
    }
  };

  const handleLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
    // console.log('Map Loaded:', mapInstance);
  }, []);

  const handleMarkerLoad = useCallback((marker) => {
    if (window.google && window.google.maps) {
      // console.log('Marker Loaded:', marker);
      // console.log('Marker Position:', marker.getPosition());
    } else {
      console.error('Google Maps API is not available.');
    }
  }, []);

  const handleMarkerClick = useCallback(
    (equipment) => {
      setSelectedMarker(equipment);
      setInfoWindowPosition({ lat: equipment.latitude, lng: equipment.longitude });
    },
    []
  );

  const handleInfoWindowClose = useCallback(() => {
    setSelectedMarker(null);
  }, []);

  const cityNames = cities?.map((city) => city.name);

  const OPTIONS = {
    minZoom: 3,
    maxZoom: 18,
  }

  const cardColor = selectedMarker?.categories?.[0]?.colorCode || '#FFF7E7';
  const averageRating = selectedMarker?.equipmentRatings ? calculateAverageRating(selectedMarker.equipmentRatings) : 0;

  // console.log("this is selected marker", selectedMarker)
  return (
    <div className='location-container' id='location-map'>
      <div className='location-search'>
        <div className="search-bar">
          <input
            type="text"
            id="input"
            placeholder="Search for location"
            value={address}
            onChange={handleInputChange}
          />
          <CiSearch size={20} onClick={handleSearch} />
        </div>
        {/* <div className="custom-dropdown" ref={dropdownRef}>
          <form>
            <label htmlFor="cities-map" className="dropdown">
              <input
                type="text"
                id="cities-map"
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                onFocus={() => setIsOpen(true)}
                placeholder="Select Area"
              />
              {isOpen ? <IoCaretUpSharp /> : <IoCaretDownSharp />}
            </label>
            {isOpen && (
              <ul className="dropdown-options">
                {cityNames?.map((option, index) => (
                  <li
                    key={index}
                    onClick={() => handleSelect(option)}
                    className={selectedOption === option ? 'selected' : ''}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            )}
          </form>
        </div> */}
      </div>


      <LoadScript googleMapsApiKey="AIzaSyB005xJuSALE7oAIxsJj0-AbW4orNeDKts"  libraries={libraries}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={zoom}
          center={mapCenter}
          onLoad={handleLoad}
          options={OPTIONS}
        >
          {equipmentsData?.map((equipment) => (
            equipment.latitude && equipment.longitude && (
              <MarkerF
                key={equipment.id}
                position={{ lat: equipment.latitude, lng: equipment.longitude }}
                icon={{
                  url: locationIcon,
                }}
                onClick={() => handleMarkerClick(equipment)}
                onLoad={handleMarkerLoad}
              />
            )
          ))}
          {selectedMarker && infoWindowPosition && (
            <InfoWindowF
              position={infoWindowPosition}
              onCloseClick={handleInfoWindowClose}

              options={{ headerDisabled: true, }}
              // style={{padding: "0px"}}
            >
              <div className='inforwindow' style={{ background: cardColor }}>
                <CgClose className='close-icon' onClick={handleInfoWindowClose}/>
                <img src={selectedMarker.image1Url} alt='product-img' />
                <Link to='/equipment-details' state={{ equipment: selectedMarker }}>
                <h4>{selectedMarker.name}</h4>
                </Link>
                <div className='stars'>
                  {renderStars(averageRating)}
                </div>
                <div className='card-bottom'>
                  <p>
                    Rent Starts From <br />
                    <span className='price'>

                        <span className='discount-price'>₹ {selectedMarker.rentalValue}/-</span>

                      <p className='rent' style={{ marginLeft: "3px" }}> ₹ {Math.round(selectedMarker.finalRentalValue)}/- Day</p>
                      <span className='discount'>{selectedMarker.discount}% Off</span>
                    </span>
                  </p>
                </div>
                {/* <p>{selectedMarker.description}</p> */}
              </div>
            </InfoWindowF>
          )}
        </GoogleMap>
      </LoadScript>

    </div>
  );
}

export default Location;
