import React, { useEffect, useState } from 'react'
import './adminpayment.scss'
import CustomDropdown from '../../CommonComponents/custom-dropdown/CustomDropdown';
import TableComponent from '../../CommonComponents/TableComponent/TableComponent';
import { admin_paymentColumns } from '../../CommonComponents/TableComponent/EntityTables/PaymentTblColumns';
import { CiSearch } from 'react-icons/ci';
import { getEquipmentData } from '../../../service/equipmentService';


import { getPayments } from '../../../service/paymentService';

function AdminPayment() {

    const [paymentData, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      fetchPayments();
      // document.addEventListener('mousedown', handleClickOutside);
      // return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const fetchPayments = async () => {

      try {
        const response = await getPayments();
        console.log("API Response:", response);
       // const data = response.data;
        setData(response);
      } catch (error) {
        console.error("Error fetching equipment data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    console.log(paymentData)


      return (

          <div className='list-container'>
              <h3>PAYMENTS</h3>
              {/* <div className='user-actions'>
                  <div className='left'>
                      <div className='search-bar'>
                          <CiSearch />
                          <input type='text' placeholder='Search...' />
                      </div>
                      <CustomDropdown
                          // options={cityNames}
                          // onSelect={(option) => console.log('Selected option:', option)}
                          placeholder="Select Area"
                      />

                      <button>GO</button>
                  </div>

              </div> */}

              <TableComponent
                columns={admin_paymentColumns}
                data={paymentData}
                hideActions={true}
              ></TableComponent>
          </div>
      );


  }
export default AdminPayment