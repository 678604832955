import { get } from "../APIHandler";

export const getRegionDropDown = async () =>{
    try {
      const responseData = await get('/DropDown/GetRegionDropDown');
      return responseData;

    } catch (error) {
          console.error('Error fetching sub category data', error);
          throw error;
    }
  }

  export const getEquipmentTypeDropDown = async () => {
    try {
        const response = await get('/DropDown/GetEquipmentTypeDropDown');
        return response;
    } catch (error) {
        console.error('Error fetching equipment type data:', error);
        throw error;
    }
};

  export const getMfgYearDropDown = async () => {
    try {
        const response = await get('/DropDown/GetMfgYearDropDown');
        return response;
    } catch (error) {
        console.error('Error fetching manufacturing year data:', error);
        throw error;
    }
};