import React, { useState, useEffect } from 'react';
import './mainpage.scss';
import { FaCaretDown } from 'react-icons/fa';
import firstImage from './assets/1.png';
import secondImage from './assets/2.png';
import thirdImage from './assets/3.jpg';

import { getCategoryDropdownOptions, getSubCategoryDropdownOptions } from '../../../service/categoryService';


import { toast } from 'sonner';
import OtpModal from './modals/OtpModal';
import { useNavigate } from 'react-router-dom';
import PasswordModal from './modals/PasswordModal';
import { generateOtpForPhoneNumber, loginService, signUpUser, verifyOtp } from '../../../service/authService';

const images = [firstImage, secondImage, thirdImage];

const MainPage = () => {
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [equipmentName, setEquipmentName] = useState('');
  const [equipmentLocation, setEquipmentLocation] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [otpModalIsOpen, setOtpModalIsOpen] = useState(false);
  const [otpFor, setOtpFor] = useState(null);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [otpData, setOtpData] = useState({});
  const [timer, setTimer] = useState(60);
  const [loading, setLoading] = useState(false);
  const [passwordModalIsOpen, setPasswordModalIsOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 4000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchCategories();
    fetchSubCategories();
  }, []);
  useEffect(() => {
    let interval;
    if (otpModalIsOpen && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [otpModalIsOpen, timer]);

  useEffect(() => {
    validateForm();
  }, [fullName, email, phone, equipmentName, equipmentLocation, selectedCategory, selectedSubcategory]);

  const validateForm = () => {
    if (activeDropdown === 0) {
      setIsFormValid(
        fullName.trim() &&
        email.trim() &&
        phone.trim() &&
        selectedCategory
        // selectedSubcategory &&
        // equipmentName.trim()
      );
    } else if (activeDropdown === 1) {
      setIsFormValid(
        fullName.trim() &&
        email.trim() &&
        phone.trim() &&
        selectedCategory
        // equipmentLocation.trim()
      );
    }
  };


  const handleResendOtp = async () => {
    try {
      setLoading(true);
      const response = await  generateOtpForPhoneNumber(phone)
      setOtpFor('phone');
      setIsOtpVerified(false);
      setOtpData({
        id: response.data.id,
        securityStamp: response.data.securityStamp,
      });
      setOtp(new Array(6).fill(''));
      setLoading(false)
      toast.success('OTP has been resent to your phone.');
      setTimer(60);
    } catch (error) {
      setLoading(false);
      console.error('Error resending OTP:', error);
      toast.error('Failed to resend OTP. Please try again.');
    }
  };


  const fetchCategories = async () => {
    try {
      const res = await getCategoryDropdownOptions();
      setCategories(res);
    } catch (error) {
      console.error("Error fetching categories");
    }
  };

  const fetchSubCategories = async () => {
    try {
      const res = await getSubCategoryDropdownOptions();
      setSubCategories(res);
    } catch (error) {
      console.error("Error fetching subcategories");
    }
  };

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  const handleDropdownClick = (index) => {
    setActiveDropdown(index === activeDropdown ? null : index);
  };

  const handleCategoryChange = (e) => {
    const selectedCategoryId = e.target.value;
    setSelectedCategory(selectedCategoryId);
  };

  const handleSubcategoryChange = (e) => {
    const selectedSubcategoryId = e.target.value;
    setSelectedSubcategory(selectedSubcategoryId);
  };


  const handlePhoneVerification = async () => {
    if (!isFormValid) return;

    if (activeDropdown === 1) {
      navigate('/equipments', {
        state: {
          fullName,
          email,
          phone,
          selectedNavCategory: selectedCategory,
        },
      });
    } else if (activeDropdown === 0) {
      try {
        const response = await  generateOtpForPhoneNumber(phone)
        setOtpFor('phone');
        setIsOtpVerified(false);
        setOtpData({
          id: response.data.id,
          securityStamp: response.data.securityStamp,
        });
        toast.success('OTP has been sent to your phone.');
        setOtpModalIsOpen(true);
      } catch (error) {
        console.error('Error generating OTP:', error);
        toast.error(error.response.data);
        // const errorMessage = error.response
        //     ? error.response
        //     : 'Failed to resend OTP. Please try again.';

        // toast.error(errorMessage);
      }
    }
  };


  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    const otpValue = otp.join('');
    try {
      const response = await verifyOtp(otpData, otpValue)
      if (response.status === 200) {
        setIsOtpVerified(true);
        toast.success('OTP Verified Successfully!');
        setOtpModalIsOpen(false);
        setOtpFor(null);
        setPasswordModalIsOpen(true);
      } else {
        toast.error('OTP Verification failed. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      toast.error('OTP Verification failed. Please try again.');
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }

    if (password.length < 8) {
      toast.error('Password should be at least 8 characters long.');
      return;
    }

    const payload = {
      firstName: fullName,
      lastName: '',
      email: email,
      phoneNumber: phone,
      password: password,
    };

    try {
      const response = await signUpUser(payload);
      if (response.message === 'User Created Successfully!') {
        toast.success('User created successfully!');
        handleLogin();
      } else {
        toast.error('Sign-up failed. Please try again.');
      }
    } catch (error) {
      console.error('Error in registering new user:', error);
      toast.error('Failed to register user. Please try again.');
    }
  };

  const handleLogin = async () => {
    try {
      const response = await loginService(phone, password)

      if (response.status === 200) {
        toast.success('Login successful!');
        const { access_token } = response.data.data;
        localStorage.setItem('accessToken', access_token);
        navigate('/dashboard/add-equipment', {
          state: {
            equipmentName,
            selectedCategory,
            selectedSubcategory,
            phone
          },
        });
      } else {
        toast.error('Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error('Login failed. Please try again.');
    }
  };

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  return (
    <>
      <div className="mainpage-container">
        <div className="mainpage-image">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Img ${index}`}
              style={{ opacity: index === currentImageIndex ? 1 : 0, transition: 'opacity 1s ease-in-out' }}
            />
          ))}
          <h1>One Stop Rental <br /> Farms, Sites, Factories.</h1>
        </div>
        <div className="dots-container">
          {images.map((_, index) => (
            <div
              key={index}
              className={`dot ${index === currentImageIndex ? 'active' : ''}`}
              onClick={() => handleDotClick(index)}
            ></div>
          ))}
        </div>
        <div className="mainpage-form">
          <div className='actions'>
            <div
              className={`dropdown ${activeDropdown === 0 ? 'active' : ''}`}
              onClick={() => handleDropdownClick(0)}
            >
              <span>I have equipment which I want to</span>
              <FaCaretDown color='#909090' />
            </div>

            <div
              className={`dropdown ${activeDropdown === 1 ? 'active' : ''}`}
              onClick={() => handleDropdownClick(1)}
            >
              <span>I need equipment for</span>
              <FaCaretDown color='#909090' />
            </div>
          </div>

          {activeDropdown === 0 && (
            <>
              <input
                type="text"
                placeholder="Full Name"
                className="textfield"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Email Id"
                className="textfield"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="text"
                placeholder="Phone no."
                className="textfield"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />

                <select
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  className="dropdown-field"
                  id='left-dropdown'
                >
                  <option value="">Select Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.value}
                    </option>
                  ))}
                </select>

                {/* <select
                  value={selectedSubcategory}
                  onChange={handleSubcategoryChange}
                  className="dropdown-field"
                >
                  <option value="">Select Subcategory</option>
                  {subCategories.map((subcategory) => (
                    <option key={subcategory.id} value={subcategory.id}>
                      {subcategory.value}
                    </option>
                  ))}
                </select>


              <input
                type="text"
                placeholder="Equipment Name"
                className="textfield"
                value={equipmentName}
                onChange={(e) => setEquipmentName(e.target.value)}
              /> */}
            </>
          )}

          {activeDropdown === 1 && (
            <>
              <input
                type="text"
                placeholder="Full Name"
                className="textfield"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Email Id"
                className="textfield"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="text"
                placeholder="Phone no."
                className="textfield"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />

              <select
                value={selectedCategory}
                onChange={handleCategoryChange}
                className="dropdown-field"
                id='right-dropdown'
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.value}>
                    {category.value}
                  </option>
                ))}
              </select>

              {/* <input
                type="text"
                placeholder="Equipment Location"
                className="textfield"
                value={equipmentLocation}
                onChange={(e) => setEquipmentLocation(e.target.value)}
              /> */}
            </>
          )}

          <button onClick={handlePhoneVerification} disabled={!isFormValid}>Submit Requirement</button>
        </div>
      </div>

      {otpModalIsOpen && (
        <OtpModal
          isOpen={otpModalIsOpen}
          onClose={() => setOtpModalIsOpen(false)}
          otp={otp}
          handleOtpChange={handleOtpChange}
          handleOtpSubmit={handleOtpSubmit}
          phoneNumber={phone}
          handleResendOtp={handleResendOtp}
          timer={timer}
          loading={loading}
        />
      )}

    {passwordModalIsOpen && (
        <PasswordModal
          isOpen={passwordModalIsOpen}
          onClose={() => setPasswordModalIsOpen(false)}
          password={password}
          confirmPassword={confirmPassword}
          setPassword={setPassword}
          setConfirmPassword={setConfirmPassword}
          handlePasswordSubmit={handlePasswordSubmit}
        />
      )}

    </>
  );
};

export default MainPage;
