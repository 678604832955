import React, { useState } from 'react';
import './forgotPassword.scss';
import { toast } from 'sonner';
import { ForgotPasswordOTP, resetPassword, verifyOtp } from '../../../service/authService';

function ForgotPassword({ onClose }) {
    const [step, setStep] = useState(1);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState({ id: '', securityStamp: '' });
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [otpInput, setOtpInput] = useState('');

    const handleValidation = () => {
        const phoneRegex = /^\d{10}$/;
        if (!phoneNumber) {
            toast.error('Phone number cannot be empty!');
            return false;
        }
        if (!phoneRegex.test(phoneNumber)) {
            toast.error('Please enter a valid 10-digit phone number!');
            return false;
        }
        return true;
    };

    const handleSendOtp = async () => {
        if (!handleValidation()) return;
    
        try {
            const response = await ForgotPasswordOTP(phoneNumber);
            setIsOtpSent(true);
            setOtp({
                id: response?.data?.id,
                securityStamp: response?.data?.securityStamp,
            });
            setStep(2);
        } catch (error) {
            console.error('Error during OTP process:', error);
            // toast.error(error.message || 'Failed to send OTP')
        }
    };
    
    


    const handleVerifyOtp = async () => {
        try {
            const response = await verifyOtp({ otp, otpInput });

            if (response?.data?.success) {
                toast.success('OTP verified!');
                setStep(3);
            } else {
                // const errorMessage = response?.data?.message || 'Failed to verify OTP';
                // toast.error(errorMessage);
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
            toast.error(errorMessage);
        }
    };

    const handlePasswordReset = async () => {
        if (newPassword !== confirmPassword) {
            toast.error('Passwords do not match!');
            return;
        }
        try {
            const response = await resetPassword(phoneNumber, newPassword);
            if (response?.data?.success) {
                toast.success('Password reset successfully!');
                onClose();
            } else {
                toast.error(response?.data?.message || 'Failed to reset password');
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Failed to reset password');
        }
    };

    return (
        <div className="forgot-password-modal">
        
            <div className="modal-content">
                <span className="close-icon" onClick={onClose}>×</span>
                {step === 1 && (
                    <>
                        <h3>Forgot Password?</h3>
                        <input
                            type="text"
                            placeholder="Enter your phone number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value.replace(/[^0-9]/g, '').slice(0, 10))}
                        />
                        <button onClick={handleSendOtp}>Send OTP</button>
                    </>
                )}
                {step === 2 && (
                    <>
                        <h3>Verify OTP</h3>
                        <input
                            type="text"
                            placeholder="Enter OTP"
                            value={otpInput}
                            onChange={(e) => {
                                const value = e.target.value.replace(/[^0-9]/g, '');
                                setOtpInput(value.slice(0, 6));
                            }}
                        />
                        <button onClick={handleVerifyOtp}>Verify OTP</button>
                    </>
                )}

                {step === 3 && (
                    <>
                        <h3>Reset Password</h3>
                        <input
                            type="password"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <input
                            type="password"
                            placeholder="Confirm new password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <div
                            className={`password-match ${
                                newPassword && confirmPassword && (newPassword === confirmPassword ? 'match' : 'no-match')
                            }`}
                        >
                            {newPassword === confirmPassword ? 'Passwords match' : 'Passwords do not match'}
                        </div>
                        <button onClick={handlePasswordReset}>Update Password</button>
                    </>
                )}
            </div>
        </div>
    );
}

export default ForgotPassword;
