import React, { useEffect, useState } from 'react';
import '../../../components/CommonComponents/FormStyle.scss'
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

import { addNewSubscription } from '../../../service/subscriptionService';
import { FiChevronLeft } from 'react-icons/fi';


function AddSubscription({subscriptionData, handleSave, view}) {
    const navigate = useNavigate();
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        displayName: '',
        price1Month: 0,
        discount1Month: 0,
        finalPrice1Month: 0,
        price3Month: 0,
        discount3Month: 0,
        finalPrice3Month: 0,
        price6Month: 0,
        discount6Month: 0,
        finalPrice6Month: 0,
        price1Year: 0,
        discount1Year: 0,
        finalPrice1Year: 0,
        equipmentCount: 0,
        contactToBeViewed: '',
        smsCountAllowed: '',
        active: true,
    });

    useEffect(() => {
        if (subscriptionData) {
            setFormData({
                ...formData,
                name: subscriptionData.name || '',
                displayName: subscriptionData.displayName || '',
                price1Month: subscriptionData.price1Month || 0,
                discount1Month: subscriptionData.discount1Month || 0,
                finalPrice1Month: subscriptionData.finalPrice1Month || 0,
                price3Month: subscriptionData.price3Month || 0,
                discount3Month: subscriptionData.discount3Month || 0,
                finalPrice3Month: subscriptionData.finalPrice3Month || 0,
                price6Month: subscriptionData.price6Month || 0,
                discount6Month: subscriptionData.discount6Month || 0,
                finalPrice6Month: subscriptionData.finalPrice6Month || 0,
                price1Year: subscriptionData.price1Year || 0,
                discount1Year: subscriptionData.discount1Year || 0,
                finalPrice1Year: subscriptionData.finalPrice1Year || 0,
                equipmentCount: subscriptionData.equipmentCount || 0,
                contactToBeViewed: subscriptionData.contactToBeViewed || '',
                smsCountAllowed: subscriptionData.smsCountAllowed || '',
                active: subscriptionData.active || true,
            });
        }
    }, [subscriptionData]);


    const calculateFinalValue = (subsValue, discount) => {
        const subs = parseFloat(subsValue) || 0;
        const disc = parseFloat(discount) || 0;
        return (subs - (subs * (disc / 100))).toFixed(2);
      };


      const handleChange = (e) => {
        const { id, value, type, checked } = e.target;
        let fieldValue = type === 'checkbox' ? checked : value;

        const numericFields = [
            'price1Month',
            'discount1Month',
            'price3Month',
            'discount3Month',
            'price6Month',
            'discount6Month',
            'price1Year',
            'discount1Year',
            'equipmentCount',
        ];

        if (numericFields.includes(id)) {
            const regex = /^\d*\.?\d{0,2}$/;
            if (!regex.test(fieldValue)) {
                return;
            }
        }

        let updatedFormData = { ...formData, [id]: fieldValue };


        if (id === 'price1Month' || id === 'discount1Month') {
            updatedFormData.finalPrice1Month = calculateFinalValue(
                updatedFormData.price1Month,
                updatedFormData.discount1Month
            );
        }
        if (id === 'price3Month' || id === 'discount3Month') {
            updatedFormData.finalPrice3Month = calculateFinalValue(
                updatedFormData.price3Month,
                updatedFormData.discount3Month
            );
        }
        if (id === 'price6Month' || id === 'discount6Month') {
            updatedFormData.finalPrice6Month = calculateFinalValue(
                updatedFormData.price6Month,
                updatedFormData.discount6Month
            );
        }
        if (id === 'price1Year' || id === 'discount1Year') {
            updatedFormData.finalPrice1Year = calculateFinalValue(
                updatedFormData.price1Year,
                updatedFormData.discount1Year
            );
        }

        setFormData(updatedFormData);
        setErrors({ ...errors, [id]: '' });
    };


    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) newErrors.name = 'Name is required';
        // if (!formData.displayName) newErrors.name = 'Display name is required';
        // if (!formData.equipmentCount) newErrors.count = 'Enter count of equipments allowed.';
        // if (!formData.price1Month) newErrors.month = 'Price is required';
        // if (!formData.price3Month) newErrors.month = 'Price is required';
        // if (!formData.price6Month) newErrors.month = 'Price is required';
        // if (!formData.price1Year) newErrors.month = 'Price is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);

        if (!validateForm()) {
            return;
        }
        const formDataToSend = {
            name: formData.name,
            displayName: formData.displayName,
            price1Month: formData.price1Month,
            discount1Month: formData.discount1Month,
            finalPrice1Month: formData.finalPrice1Month,
            price3Month: formData.price3Month,
            discount3Month: formData.discount3Month,
            finalPrice3Month: formData.finalPrice3Month,
            price6Month: formData.price6Month,
            discount6Month: formData.discount6Month,
            finalPrice6Month: formData.finalPrice6Month,
            price1Year: formData.price1Year,
            discount1Year: formData.discount1Year,
            finalPrice1Year: formData.finalPrice1Year,
            equipmentCount: formData.equipmentCount,
            contactToBeViewed: 1,
            smsCountAllowed: 1,
            active: formData.active,
            ...(subscriptionData?.id && { id: subscriptionData.id })
        };



        try {
            if (subscriptionData) {

                await handleSave(formDataToSend);
                toast.success('Subscription Updated Successfully!', { variant: 'success' });
            } else {

                const response = await addNewSubscription(formDataToSend);
                toast.success('Subscription Created Successfully!', { variant: 'success' });
            }
        } catch (error) {
            toast.error("Failed to save subscription.", { variant: 'error' });
            console.error("There was an error submitting the form!", error);
        }
    };



    return (
        <div className='form-container'>
             {/* {subscriptionData ? */}
              <button className='back-btn' onClick={() => navigate(-1)}>
                <FiChevronLeft size={16} /><p>Back to Results</p>
            </button>
            {/* //  : ''} */}

      <h2>{handleSave
        ? 'Edit Subscription'
        : view
            ? 'View Subscription'
            : 'Add Subscription'}</h2>
            <form onSubmit={handleSubmit}>

                <div className={`split-input `}>
                    <div className='input-field'>
                        
                    <label className='input-label' htmlFor='name'>Name</label>
                    <input
                        className={`input-text ${errors.name ? 'error' : ''}`}
                        type='text'
                        id='name'
                        value={formData.name}
                        onChange={handleChange}
                        disabled={view}
                    />
                    </div>
                    <div className='input-field'>
                        
                    <label className='input-label' htmlFor='name'>Display Name</label>
                    <input
                        className={`input-text`}
                        type='text'
                        id='displayName'
                        value={formData.displayName}
                        onChange={handleChange}
                        disabled={view}
                    />
                    </div>
                </div>

                <div className={`input-field `}>
                    <label className='input-label' htmlFor='equipmentCount'>Equip. Count</label>
                    <input
                        className={`input-text ${errors.equipmentCount ? 'error' : ''}`}
                        type='text'
                        id='equipmentCount'
                        value={formData.equipmentCount}
                        onChange={handleChange}
                        disabled={view}
                    />

                </div>

                {/* For 1 Month */}
                <div className='split-input'>
                    <div className='input-field'>

                    <label className='input-label' htmlFor='rentalValue'>Price 1 Month</label>
                    <input
                        className={`input-text ${errors.price1Month ? 'error' : ''}`}
                        type='text'
                        id='price1Month'
                        value={formData.price1Month}
                        onChange={handleChange}
                        disabled={view}
                    />
                    </div>
                    <div className='input-field'>
                        
                    <label className='input-label' htmlFor='discount'>Disc. 1 Month(%)</label>
                    <input
                        className='input-text'
                        type='text'
                        id='discount1Month'
                        value={formData.discount1Month}
                        onChange={handleChange}
                        disabled={view}
                    />
                        </div>
                    <div className='input-field'>
                        
                    <label className='input-label' htmlFor='finalValue'>Final Price</label>
                    <input
                        className={`input-text `}
                        type='text'
                        id='finalPrice1Month'
                        value={formData.finalPrice1Month}
                        onChange={handleChange}
                        disabled={view}
                        readOnly
                    />
                        </div>
                </div>

                {/* For 2 Month */}
                <div className='split-input'>
                <div className='input-field'>
                        
                    <label className='input-label' htmlFor='rentalValue'>Price 3 Month</label>
                    <input
                        className={`input-text ${errors.price3Month ? 'error' : ''}`}
                        type='text'
                        id='price3Month'
                        value={formData.price3Month}
                        onChange={handleChange}
                        disabled={view}
                    />
                    </div>
                    <div className='input-field'>
                        
                    <label className='input-label' htmlFor='discount'>Disc. 3 Month(%)</label>
                    <input
                        className='input-text'
                        type='text'
                        id='discount3Month'
                        value={formData.discount3Month}
                        onChange={handleChange}
                        disabled={view}
                    />
                        </div>
                    <div className='input-field'>
                        
                    <label className='input-label' htmlFor='finalValue'>Final Price</label>
                    <input
                        className={`input-text `}
                        type='text'
                        id='finalPrice3Month'
                        value={formData.finalPrice3Month}
                        onChange={handleChange}
                        disabled={view}
                        readOnly
                    />
                        </div>
                </div>

                {/* For 3 Month */}
                <div className='split-input'>
                <div className='input-field'>
                        
                    <label className='input-label' htmlFor='rentalValue'>Price 6 Month</label>
                    <input
                        className={`input-text ${errors.price6Month ? 'error' : ''}`}
                        type='text'
                        id='price6Month'
                        value={formData.price6Month}
                        onChange={handleChange}
                        disabled={view}
                    />
                    </div>
                    <div className='input-field'>
                        
                    <label className='input-label' htmlFor='discount'>Disc. 6 Month(%)</label>
                    <input
                        className='input-text'
                        type='text'
                        id='discount6Month'
                        value={formData.discount6Month}
                        onChange={handleChange}
                        disabled={view}
                    />
                        </div>
                    <div className='input-field'>
                        
                    <label className='input-label' htmlFor='finalValue'>Final Price</label>
                    <input
                        className={`input-text `}
                        type='text'
                        id='finalPrice6Month'
                        value={formData.finalPrice6Month}
                        onChange={handleChange}
                        disabled={view}
                        readOnly
                    />
                        </div>
                </div>

                {/* For  1 year */}
                <div className='split-input'>
                <div className='input-field'>
                        
                    <label className='input-label' htmlFor='rentalValue'>Price 1 Year</label>
                    <input
                        className={`input-text ${errors.price1Year ? 'error' : ''}`}
                        type='text'
                        id='price1Year'
                        value={formData.price1Year}
                        onChange={handleChange}
                        disabled={view}
                    />
                    </div>
                    <div className='input-field'>
                        
                    <label className='input-label' htmlFor='discount'>Disc. 1 Year(%)</label>
                    <input
                        className='input-text'
                        type='text'
                        id='discount1Year'
                        value={formData.discount1Year}
                        onChange={handleChange}
                        disabled={view}
                    />
                        </div>
                    <div className='input-field'>
                        
                    <label className='input-label' htmlFor='finalValue'>Final Price</label>
                    <input
                        className={`input-text `}
                        type='text'
                        id='finalPrice1Year'
                        value={formData.finalPrice1Year}
                        onChange={handleChange}
                        disabled={view}
                        readOnly
                    />
                        </div>
                </div>
                   <p className='error-text'>{errors.name}</p>
                <div className='btns-container'>
                    {!view && (

                        <button type='submit' className='save-btn'>SAVE</button>

                    )}

                    <button type='button' className='cancel-btn' onClick={() => navigate(-1)}>CANCEL</button>
                </div>
            </form>


        </div>
    )
};

export default AddSubscription;

