import React, { useEffect, useRef, useState, useCallback, useMemo } from "react";
import "./dropdown.scss";
import { IoCaretDownSharp, IoCaretUpSharp } from "react-icons/io5";

function Dropdown({
  label,
  options = [],
  onChange,
  id,
  value = [],
  multiple = false,
  error = false,
  reset,
  readOnly = false,
  disable = false,
  otherValue = false, // Prop to conditionally show "Other"
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [search, setSearch] = useState("");

  const dropdownRef = useRef(null);

  useEffect(() => {
    const normalizedValue = Array.isArray(value) ? value : [value];
    setSelectedOptions(
      options.filter((opt) => normalizedValue.includes(opt.id))
    );
  }, [value, options]);

  useEffect(() => {
    if (reset) {
      setSelectedOptions([]);
    }
  }, [reset]);

  const handleClickOutside = useCallback((event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }, []);

  const handleSelect = useCallback(
    (option) => {
      if (option.id === "other") {
        const otherOption = { id: "other", value: "Other" };
  
        if (multiple) {
          
          const updatedOptions = selectedOptions.some((opt) => opt.id === "other")
            ? selectedOptions.filter((opt) => opt.id !== "other")
            : [...selectedOptions, otherOption];
  
          setSelectedOptions(updatedOptions);
          onChange(updatedOptions.map((opt) => opt.id));
        } else {
          
          setSelectedOptions([otherOption]);
          onChange(otherOption.id);
          setIsOpen(false);
        }
      } else {
        if (multiple) {
          
          const updatedOptions = selectedOptions.some((opt) => opt.id === option.id)
            ? selectedOptions.filter((opt) => opt.id !== option.id)
            : [...selectedOptions, option];
  
          setSelectedOptions(updatedOptions);
          onChange(updatedOptions.map((opt) => opt.id));
        } else {
          
          setSelectedOptions([option]);
          onChange(option.id);
          setIsOpen(false);
        }
      }
    },
    [multiple, onChange, selectedOptions]
  );
  

  const inputValue = useMemo(() => {
    if (isOpen) {
      return search;
    }
    if (selectedOptions.some((opt) => opt.id === "other")) {
      return "Other";
    }
    return selectedOptions.map((opt) => opt.value).join(", ");
  }, [isOpen, search, selectedOptions]);

  const handleToggleDropdown = useCallback(() => {
    if (!disable) {
      setIsOpen((prev) => !prev);
    }
  }, [disable]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [handleClickOutside]);

  const filteredOptions = [
    ...options.filter((option) =>
      option.value.toLowerCase().includes(search.toLowerCase())
    ),
  ];

  
  if (otherValue && !filteredOptions.some((option) => option.id === "other")) {
    filteredOptions.push({ id: "other", value: "Other" });
  }

  return (
    <div
      className={`add-equipment-dropdown ${disable ? "disabled" : ""}`}
      ref={dropdownRef}
    >
      <label htmlFor={id} className={`dropdown ${error ? "error" : ""}`}>
        <input
          type="text"
          id={id}
          value={inputValue}
          readOnly={readOnly || disable}
          placeholder={label}
          onClick={handleToggleDropdown}
          onChange={(e) => setSearch(e.target.value)}
          disabled={disable}
          autoComplete="off"
        />
        {isOpen ? <IoCaretUpSharp /> : <IoCaretDownSharp />}
      </label>
      {isOpen && (
        <ul className="dropdown-options">
          {filteredOptions.map((option, index) => (
            <li key={index} onClick={() => handleSelect(option)}>
              {option.id === "other" ? (
                <>Other</>
              ) : (
                <>
                  {multiple ? (
                    <input
                      type="checkbox"
                      checked={selectedOptions.some((opt) => opt.id === option.id)}
                      onChange={() => handleSelect(option)}
                    />
                  ) : (
                    <div className="dot" />
                  )}
                  {option.value}
                </>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Dropdown;
