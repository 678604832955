import React, { useState, useEffect, useContext } from 'react';
import './subscriptionlist.scss';
import Button from '../../../dashboard/common-components/buttons/Button';
import TableComponent from '../../CommonComponents/TableComponent/TableComponent';
import { Link, useNavigate } from 'react-router-dom';
import UserContext from '../../../context/userContext';
import { GetPurchasedSubscriptions, GetSubscriptions } from '../../../service/subscriptionService';
import { LoadingContext } from '../../../context/LoadingContext';

const SubscriptionList = () => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const [data, setData] = useState([]);
    const { showLoader, hideLoader } = useContext(LoadingContext);

    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                showLoader();
                let response;
                if (user && user.role) {
                    if (user.role.toLowerCase() === 'admin') {
                        response = await GetSubscriptions();
                    } else {
                        response = await GetPurchasedSubscriptions(user.loggedInUserId);
                    }
                }
                const transformedData = transformData(response);
                setData(transformedData);
                hideLoader();
            } catch (error) {
                console.error('Error fetching subscription plans:', error);
                hideLoader();
            }
        };
        fetchSubscriptions();
    }, [user]);

    const transformData = (data) => {
        return data.map(plan => ({
            id: plan.id,
            subscriptionId: plan.subscriptionId,
            displayName: plan.displayName,
            finalPrice1Month: plan.finalPrice1Month,
            finalPrice2Month: plan.finalPrice2Month,
            finalPrice3Month: plan.finalPrice3Month,
            finalPrice1Year: plan.finalPrice1Year,
            equipmentCount: plan.equipmentCount,
            smsServices: plan.smsCountAllowed,
            active: plan.active,
        }));
    };


    const handleView = (id) => {
        handleNavigate(`/dashboard/subscription-admin/view/${id}`);
        console.log("View clicked", id);
    };

    const handleEdit = (id) => {
        handleNavigate(`/dashboard/subscription-admin/edit/${id}`);
        console.log("Edit clicked", id);
    };

    const handleRate = (id) => {

        console.log("Rate clicked", id);
    };


    const handleNavigate = (path) => {
        navigate(path);
    };

    const formatCurrency = (value) => {
        if (isNaN(value)) return '₹0.00';
        return `₹${Number(value).toFixed(2)}/-`;
    };

    const columns = [
        { Header: 'Plan Name', accessor: 'displayName' },
        ...(user && user.role.toLowerCase() === 'admin' ? [
            {
                Header: '1 Month',
                headerStyle: { textAlign: 'right' },
                accessor: 'finalPrice1Month',
                Cell: ({ value }) => (
                    <div style={{ textAlign: 'right' }}>
                        {formatCurrency(value)}
                    </div>
                ),

            },
            {
                Header: '2 Months',
                headerStyle: { textAlign: 'right' },
                accessor: 'finalPrice2Month',
                Cell: ({ value }) => (
                    <div style={{ textAlign: 'right' }}>
                        {formatCurrency(value)}
                    </div>
                )
            },
            {
                Header: '3 Months',
                headerStyle: { textAlign: 'right' },
                accessor: 'finalPrice3Month',
                Cell: ({ value }) => (
                    <div style={{ textAlign: 'right' }}>
                        {formatCurrency(value)}
                    </div>
                )
            },
            {
                Header: '1 Year',
                headerStyle: { textAlign: 'right' },
                accessor: 'finalPrice1Year',
                Cell: ({ value }) => (
                    <div style={{ textAlign: 'right' }}>
                        {formatCurrency(value)}
                    </div>
                )
            },
        ] : []),
        {
            Header: 'Equipment Count',
            accessor: 'equipmentCount',
            Cell: ({ value }) => (
                <div style={{ textAlign: 'center' }}>{value}</div>
            )
        },
        { Header: 'SMS Count', accessor: 'smsServices' },
    ];



    return (
        <div className='subscriptionlist-container'>
            <header>
                <h3 className='heading'>Subscription List</h3>
                {user && user.role && user.role.toLowerCase() === 'admin' ? (
                    <Link to='/dashboard/subscription-add'>
                        <Button className='save-btn' label="Add Plan" />
                    </Link>
                ) : (
                    <Link to='/dashboard/subscriptions'>
                        <Button className='save-btn' label="Buy Subscription" />
                    </Link>
                )}
            </header>
            <TableComponent
                columns={columns}
                data={data}
                onView={handleView}
                onEdit={handleEdit}
                className="table-component"
                itemsPerPage="12"
                hideActions={false}
                extraActions={true}
                subscriptionActions={{
                    onView: handleView,
                    onEdit: handleEdit,
                    onRate: handleRate,
                }}
                handleNavigate={handleNavigate}
                // enableCollapsible={true}
            />
        </div>
    );
};

export default SubscriptionList;
