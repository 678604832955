import React, { useContext, useEffect, useRef, useState } from 'react'

import TableComponent from '../../CommonComponents/TableComponent/TableComponent';
import {customerColumns} from "../../CommonComponents/TableComponent/EntityTables/CustomerTblColumns";
import CustomDropdown from '../../CommonComponents/custom-dropdown/CustomDropdown';
import { CiSearch } from 'react-icons/ci';
import { getUsersList } from '../../../service/customerService';
import { useNavigate } from 'react-router-dom';
import { LoadingContext } from '../../../context/LoadingContext';


function CustomerList(){
  const { showLoader, hideLoader } = useContext(LoadingContext);
    const navigate = useNavigate();
    const [paymentData, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      fetchCustomers();
      // document.addEventListener('mousedown', handleClickOutside);
      // return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const fetchCustomers = async () => {
      showLoader();
      try {
        const response = await getUsersList();
        setData(response);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
        hideLoader();
      }
    }


    const handleView = (id) => {
      handleNavigate(`/dashboard/customer/view/${id}`);
      console.log("View clicked", id);
  };

  const handleEdit = (id) => {
      handleNavigate(`/dashboard/customer/edit/${id}`);
      console.log("Edit clicked", id);
  };

  const handleNavigate = (path) => {
    navigate(path);
};

    return(
        <div className='list-container'>
        <h3>CUSTOMER LIST</h3>
        <div className='user-actions'>
            <div className='left'>
                <div className='search-bar'>
                    <CiSearch />
                    <input type='text' placeholder='Search...' />
                </div>
                <CustomDropdown
                    // options={cityNames}
                    // onSelect={(option) => console.log('Selected option:', option)}
                    placeholder="Select Area"
                />

                <button>GO</button>
            </div>

        </div>

        <TableComponent
        columns={customerColumns}
        data={paymentData}
        itemsPerPage="12"
        hideActions={false}
        extraActions={true}
        subscriptionActions={{
            onView: handleView,
            onEdit: handleEdit,
        }}/>
    </div>
    );
}
export default CustomerList