import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { GetSubscriptionById } from '../../../service/subscriptionService';
import { updateEquipment } from '../../../service/equipmentService';
import AddSubscription from './AddSubscription';

function ViewSubscription() {
    const { id } = useParams();
    const [subscriptionData, setSubscriptionData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
          try {
            if (id) {
            const response = await GetSubscriptionById(id);
            setSubscriptionData(response);
            setLoading(false);
        }
          } catch (error) {
            setError(error.message);
            setLoading(false);
          }
        };

        fetchData();

      }, []);

      console.log(subscriptionData)


      const handleSave = async (updatedData) => {
        try {
          await updateEquipment(updatedData);
          navigate('/dashboard/equipments-list');
        } catch (err) {
          setError(err.message);
        }
      };


      if (loading) return <div>Loading...</div>;
      if (error) return <div>Error: {error}</div>;
  return (
    <AddSubscription subscriptionData={subscriptionData} view={true}/>
  )
}

export default ViewSubscription