import React, { useEffect, useState } from 'react';
import './App.css';
import HomeComponent from './components/home';
import ProductsComponent from './components/products';
import Header from './components/home/header/Header';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet'
import Lenis from 'lenis';
import TempHeader from './components/home/tempHeader/TempHeader';
import Login from './components/auth/login/Login';
import SignUp from './components/auth/signup/SignUp';
import { UserProvider } from './context/userContext';
import EquipmentDetails from './components/equipment-details/EquipmentDetails';
import Footer from './components/footer/Footer';
import DashboardLayout from './dashboard';
import EquipmentList from './dashboard/equipmentList/EquipmentList';
import Profile from './dashboard/profile/Profile';
import Subscriptions from './dashboard/subscriptions/Subscriptions';
import Settings from './dashboard/settings/Settings';
import Addequipment from './dashboard/add-equipment/Addequipment';
import PaymentList from './components/AdminComponents/Payments/PaymentList';
import SupplierList from './components/AdminComponents/Supplier/Supplierlist';
import CustomerList from './components/AdminComponents/Customer/CustomerList';
import Dashboard from './dashboard/Dashboard';
import SubscriptionList from './components/AdminComponents/subscription-list/SubscriptionList';
import AdminPayment from './components/AdminComponents/admin-payment/AdminPayment';
import AdminSupplier from './components/AdminComponents/Supplier/AdminSupplier';
import AdminCustomer from './components/AdminComponents/Customer/AdminCustomer';
import { Toaster } from 'sonner';
import SubscriptionPlan from './components/suppliercomponents/plans/SubscriptionPlan';
import AddSubscription from './components/AdminComponents/subscription-list/AddSubscription';
import EditEquipment from './dashboard/add-equipment/split-components/EditEquipment';
import ViewEquipment from './dashboard/add-equipment/split-components/ViewEquipment';
import DynamicEquipment from './components/products/pages/dynamic-pages/DynamicEquipment';
import { SearchProvider } from './context/SearchContext ';
import { LoadingProvider } from './context/LoadingContext';
import GlobalLoader from './components/CommonComponents/global-loader/GlobalLoader';
import RecentVisited from './dashboard/reccently-visited/RecentVisited';
import Master from './dashboard/master/master/Master';
import EquipmentType from './dashboard/master/equipment type/EquipmentType';
import EquipmentCategory from './dashboard/master/equipment category/EquipmentCategory';
import TermsConditions from './components/Terms&Conditions/TermsConditions';
import ContactUs from './components/contact-us/ContactUs';
import RefundPolicy from './components/refundPolicy/RefundPolicy';
import CashfreeGateway from './components/payment-gateway/cashfree/CashfreeGateway';
import PrivateRoute from './PrivateRoute';
import { CityProvider } from './context/LocationContext';
import ViewSubscription from './components/AdminComponents/subscription-list/ViewSubscription';
import EditSubscription from './components/AdminComponents/subscription-list/EditSubscription';
import ViewCustomer from './components/AdminComponents/Customer/ViewCustomer';
import EditCustomer from './components/AdminComponents/Customer/EditCustomer';

function App() {
  const location = useLocation();
  // const lenis = new Lenis();
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);

  // useEffect(() => {
  //   function raf(time) {
  //     lenis.raf(time);
  //     requestAnimationFrame(raf);
  //   }

  //   requestAnimationFrame(raf);
  // }, []);

  const handleLoginClick = () => setShowLogin(true);
  const handleSignupClick = () => setShowSignup(true);
  const handleClose = () => {
    setShowLogin(false);
    setShowSignup(false);
  };

  const showSignupFromLogin = () => {
    setShowLogin(false);
    setShowSignup(true);
  };

  const showLoginFromSignup = () => {
    setShowSignup(false);
    setShowLogin(true);
  };

  const hideHeaderRoutes = [
    // '/terms-and-conditions',
    // '/refund-policy',
    // '/contact-us'
  ];

  const shouldHideHeader = hideHeaderRoutes.includes(location.pathname);

  return (
    <div className="App">
       <Helmet>
        <title>MachinesOnRent - Agricultural, Industrial & Construction Equipment Rentals</title>
        <meta
          name="description"
          content="MachinesOnRent offers a wide range of agricultural, industrial, and construction equipment for rent, helping you find the right tools and machines for your needs, quickly and affordably."
        />
        <meta
          name="keywords"
          content="machines on rent, agricultural equipment rental, industrial machinery rental, construction equipment for rent, local equipment rental, rent tractors, rent excavators, rent loaders, rent agricultural machinery"
        />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href="https://www.machinesonrent.com" />
      </Helmet>


      <LoadingProvider>
          <SearchProvider>
      <CityProvider>
      <UserProvider>
        <TempHeader handleLoginClick={handleLoginClick} />
        {!location.pathname.startsWith('/dashboard') && !shouldHideHeader && <Header />}
        <Toaster position="top-center" />
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<HomeComponent handleLoginClick={handleLoginClick} />} />
          <Route path="/equipments" element={<DynamicEquipment />} />
          <Route path="/equipments/:query" element={<DynamicEquipment />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="/cashfree-payment-gateway" element={<CashfreeGateway />} />



          {/* <Route path="/login" element={<Overlay><Login /></Overlay>} /> */}
          {/* <Route path="/signup" element={<Overlay><SignUp /></Overlay>} /> */}
          <Route path="/equipment-details" element={<EquipmentDetails showLogin={showLoginFromSignup} handleLoginClick={handleLoginClick} />} />
          <Route
                path="/dashboard/*"
                element={
                  <PrivateRoute>
                    <DashboardLayout />
                  </PrivateRoute>
                }
              >
            <Route path="" element={<Dashboard />} />
            <Route path="profile" element={<Profile />} />
            <Route path="equipments-list" element={<EquipmentList />} />
            <Route path="equipments-list/view/:id" element={<ViewEquipment />} />
            <Route path="equipments-list/edit/:id" element={<EditEquipment />} />
            <Route path="recently-visited" element={<RecentVisited />} />
            <Route path="payments" element={<PaymentList />} />
            <Route path="admin-payments" element={<AdminPayment />} />
            <Route path="subscriptions" element={<Subscriptions />} />
            <Route path="settings" element={<Settings />} />
            <Route path="add-equipment" element={<Addequipment />} />
            <Route path="supplier" element={<SupplierList />} />
            <Route path="admin-supplier" element={<AdminSupplier />} />
            <Route path="customer" element={<CustomerList />} />
            <Route path="customer/view/:id" element={<ViewCustomer />} />
            <Route path="customer/edit/:id" element={<EditCustomer />} />
            <Route path="admin-customer" element={<AdminCustomer />} />
            <Route path="subscription-admin" element={<SubscriptionList />} />
            <Route path="subscription-admin/view/:id" element={<ViewSubscription />} />
            <Route path="subscription-admin/edit/:id" element={<EditSubscription />} />
            <Route path="subscription-plan" element={<SubscriptionPlan />} />
            <Route path="subscription-add" element={<AddSubscription />} />




            <Route path="master" element={<Master />} >
                <Route path="master-equipment-type" element={<EquipmentType/>}/>
                <Route path="master-equipment-category" element={<EquipmentCategory/>}/>
            </Route>
          </Route>
        </Routes>

        <GlobalLoader />

        {location.pathname.startsWith('/dashboard') ? null : <Footer />}
        {showLogin && (
            <Login onClose={handleClose} showSignup={showSignupFromLogin} />
        )}
        {showSignup && (
            <SignUp onClose={handleClose} showLogin={showLoginFromSignup} />
        )}
      </UserProvider>
      </CityProvider>
      </SearchProvider>
      </LoadingProvider>

    </div>
  );
}

export default App;
