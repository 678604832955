import React from 'react'
import './downloadapp.scss'
import phone from './phn.png'
import QrCode from './qr.png'
import { IoLogoGooglePlaystore } from "react-icons/io5";
import { FaApple } from "react-icons/fa";

function DownloadApp() {
  return (
    <div className='download-container'>
        <span className='big'/>
        <span className='med'/>
        <span className='small'/>
        <div className='phone'>
        <img src={phone} className='phone-img' alt='phone-img'/>

        </div>

        <div className='text-qr'>
            <div className='top'>
                <h1 className='commingsoon'>Comming Soon !!!</h1>
                {/* <div className='left'>
                    <h1>
                        Download App Now!

                    </h1>
                </div> */}
                {/* <div className='right'>
                    <img src={QrCode} alt='qr code'/>
                    <p>Scan to download<br/> IOS and android<br/> app </p>

                </div> */}
            </div>
            {/* <div className='bottom'>
                <button className='app-btn'>
                    <IoLogoGooglePlaystore size={35}/>
                    <p>
                        GET IT ON <br/>
                        <span>Google Play</span>
                    </p>
                </button>
                <button className='app-btn'>
                    <FaApple size={35}/>
                    <p>
                        DOWNLOAD ON THE<br/>
                        <span>App Store</span>
                    </p>
                </button>
            </div> */}
        </div>

    </div>
  )
}

export default DownloadApp