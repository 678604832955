import React, { useState, useEffect, useContext } from 'react';
import './subscriptions.scss';
import Tab from './tabs/Tab';
import DynamicCard from './dynamic-card/DynamicCard';
import { GetSubscriptions } from '../../service/subscriptionService';
import { LoadingContext } from '../../context/LoadingContext';

function Subscriptions() {
  const [activeTab, setActiveTab] = useState(0);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);

  const { showLoader, hideLoader } = useContext(LoadingContext);

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        showLoader();
        const response = await GetSubscriptions();
        const plans = transformData(response);
        setSubscriptionPlans(plans);
        hideLoader();
      } catch (error) {
        console.error('Error fetching subscription plans:', error);
        hideLoader();
      }
    };

    fetchSubscriptions();
  }, []);

  const transformData = (data) => {
    return [
      {
        duration: '1 MONTH',
        cards: data.map(plan => ({
          id: plan.id,
          title: plan.name,
          discount: plan.discount1Month,
          discountPrice: plan.price1Month,
          price: plan.finalPrice1Month,
          priceUnit: '/ mo',
          buttonText: 'BUY NOW',
          features: [
            `Equipment Count: ${plan.equipmentCount}`,
            // `Contacts to be Viewed: ${plan.contactToBeViewed}`,
             `SMS Count Allowed: ${plan.smsCountAllowed}`
          ],
          equipmentCount: plan.equipmentCount,
          // bestseller: plan.name === 'ECONOMY'
        }))
      },
      {
        duration: '3 MONTHS',
        cards: data.map(plan => ({
          id: plan.id,
          title: plan.name,
          discount: plan.discount2Month,
          discountPrice: plan.price2Month,
          price: plan.finalPrice2Month,
          priceUnit: '/ 3 mo',
          buttonText: 'BUY NOW',
          features: [
            `Equipment Count: ${plan.equipmentCount}`,
            // `Contacts to be Viewed: ${plan.contactToBeViewed}`,
             `SMS Count Allowed: ${plan.smsCountAllowed}`
          ],
          equipmentCount: plan.equipmentCount,
          // bestseller: plan.name === 'ECONOMY'
        }))
      },

      {
        duration: '6 MONTHS',
        cards: data.map(plan => ({
          id: plan.id,
          title: plan.name,
          discount: plan.discount3Month,
          discountPrice: plan.price3Month,
          price: plan.finalPrice3Month,
          priceUnit: '/ 6 mo',
          buttonText: 'BUY NOW',
          features: [
            `Equipment Count: ${plan.equipmentCount}`,
            // `Contacts to be Viewed: ${plan.contactToBeViewed}`,
             `SMS Count Allowed: ${plan.smsCountAllowed}`
          ],
          equipmentCount: plan.equipmentCount,
          bestseller: plan.name === 'ECONOMY'
        }))
      },
      {
        duration: '1 YEAR',
        cards: data.map(plan => ({
          id: plan.id,
          title: plan.name,
          discount: plan.discount1Year,
          discountPrice: plan.price1Year,
          price: plan.finalPrice1Year,
          priceUnit: '/ 1 year',
          buttonText: 'BUY NOW',
          features: [
            `Equipment Count: ${plan.equipmentCount}`,
            // `Contacts to be Viewed: ${plan.contactToBeViewed}`,
             `SMS Count Allowed: ${plan.smsCountAllowed}`
          ],
          equipmentCount: plan.equipmentCount,
          // bestseller: plan.name === 'ECONOMY'
        }))
      },
    ];
  };

  if (subscriptionPlans.length === 0) {
    return showLoader();
  }

  return (
    <div className='subscription-container'>
      <h3 className='heading'>SUBSCRIPTIONS</h3>
      <h2 className='heading bottom'>Choose Your Best Plan.</h2>

      <div className='subscription-card'>
        <div className='top'>
          {subscriptionPlans.map((plan, index) => (
            <Tab
              key={index}
              label={plan.duration}
              onClick={() => setActiveTab(index)}
              isActive={activeTab === index}
            />
          ))}
        </div>

        <div className="tabs-content">
          <DynamicCard cards={subscriptionPlans[activeTab].cards} />
        </div>
      </div>
    </div>
  );
}

export default Subscriptions;
