import React, { useState, useRef, useEffect, useContext } from 'react';
import './header.scss';
import { CiSearch } from 'react-icons/ci';
import { IoCaretDownSharp, IoCaretUpSharp } from "react-icons/io5";
import { Link, useNavigate } from 'react-router-dom';
import CityNav from './CityNav';
import { AnimatePresence } from 'framer-motion';
import { SearchContext } from '../../../context/SearchContext ';
import MORLogo from './LogoMOR.png'
import cities from '../../../Constants/Cities';

function Header() {
  const navigate = useNavigate();
  const { searchQuery, setSearchQuery, triggerSearch } = useContext(SearchContext);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState(cities[7]);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectCity = (city) => {
    setSelectedCity(city);
    setIsOpen(false);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery) {
        navigate(`/equipments/${searchQuery}`);
    }
  };

  return (
    <div className="header-component">
      <Link to="/">
      <div className='logo-mor'>

          <img src={MORLogo} alt='MOR Logo' className='mor-img'/>

          <div className="logo">
            <h2>Machines</h2>
            <h3><i>On</i> Rent</h3>
          </div>

      </div>
      </Link>
      <div className="search-bar">
        <form className='search-field' onSubmit={handleSearchSubmit}>
          <CiSearch onClick={handleSearchSubmit} />
          <input
            type="text"
            placeholder="Search equipment..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                triggerSearch(); // Trigger the search when Enter is pressed
              }
            }}

          />
        </form>
      </div>

      <div className="custom-city-select" ref={dropdownRef}>
        <form>
          <label htmlFor="cities" className="dropdown">
            <input
              type="text"
              id="cities"
              value={selectedCity.name}
              onChange={() => {}}
              onFocus={() => setIsOpen(true)}
              placeholder="SELECT YOUR CITY"
              readOnly
            />
            {isOpen ? <IoCaretUpSharp /> : <IoCaretDownSharp />}
          </label>

          <AnimatePresence mode="wait">
            {isOpen && (
              <CityNav handleOpen={handleOpen} handleSelectCity={handleSelectCity} selectedCityId={selectedCity.id} />
            )}
          </AnimatePresence>
        </form>
      </div>
    </div>
  );
}

export default Header;
