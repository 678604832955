import React from 'react';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import './equipmentcard.scss';

function EquipmentCard({ img, rent, name, isSelected, onSelect, disabled, ratings, rentalType }) {

  const averageRating = Array.isArray(ratings)
    ? ratings.reduce((total, rating) => total + rating.starRating, 0) / ratings.length
    : ratings;

  // console.log('Average Rating:', averageRating);

  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(Math.max(rating, 0));
    const halfStar = Math.abs(rating % 1) >= 0.5;

    for (let i = 0; i < fullStars; i++) {
      stars.push(<FaStar key={i} className='star full' />);
    }

    if (halfStar) {
      stars.push(<FaStarHalfAlt key='half' className='star half' />);
    }

    const remainingStars = 5 - stars.length;
    for (let i = 0; i < remainingStars; i++) {
      stars.push(<FaRegStar key={i + fullStars + 1} className='star empty' />);
    }

    return stars;
  };

  const getRentalType = (type) => {
    switch (type) {
      case 0: return 'Hours';
      case 1: return 'Day';
      case 2: return 'Week';
      case 3: return 'Month';
      default: return '';
    }
  };

  return (
    <div className={`equipment-card-container ${disabled ? 'disabled' : ''}`}>
      <div className='left'>
        <img
          src={img}
          alt='card-img'
          onError={(e) => {
            e.target.onerror = null;
            e.target.src =
              'https://i.pinimg.com/564x/bb/af/4a/bbaf4ae73da10ad1979607afe4f8965c.jpg';
          }}
        />
        <h3>{name}</h3>
      </div>

      <div className='right'>
        <div className='rent'>
          <p className='rent-text'>Rent Starts from</p>
          <h3>₹{rent}/-{getRentalType(rentalType)}</h3>
        </div>

        <div className='ratings'>
          {renderStars(averageRating)}
        </div>

        <label className='custom-checkbox'>
          <input
            type='checkbox'
            checked={isSelected}
            onChange={onSelect}
            disabled={disabled}
          />
          <span></span>
        </label>
      </div>
    </div>
  );
}

export default EquipmentCard;