import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  GoogleMap,
  Marker,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
import InputField from "./InputField";

const libraries = ["places"];

const MapComponent = ({
  coordinates,
  setCoordinates,
  markerPosition,
  setMarkerPosition,
  setAddress,
  setCity,
  setState,
  setPincode,
  setCountry,
}) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyB005xJuSALE7oAIxsJj0-AbW4orNeDKts",
    libraries,
  });
  const [mapStyle, setMapStyle] = useState({ width: "250px", height: "200px" });

  const onMapClick = useCallback(
    (event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      setCoordinates({ lat, lng });
      setMarkerPosition({ lat, lng });
    },
    [setCoordinates, setMarkerPosition]
  );

  const onMarkerDragEnd = useCallback(
    (event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      setCoordinates({ lat, lng });
      setMarkerPosition({ lat, lng });

      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && results[0]) {
          const place = results[0];
          const address = place.formatted_address;
          const city =
            place.address_components.find((component) =>
              component.types.includes("locality")
            )?.long_name || "";
          const state =
            place.address_components.find((component) =>
              component.types.includes("administrative_area_level_1")
            )?.long_name || "";
          const pincode =
            place.address_components.find((component) =>
              component.types.includes("postal_code")
            )?.long_name || "";
          const country =
            place.address_components.find((component) =>
              component.types.includes("country")
            )?.long_name || "";

          setAddress(address);
          setCity(city);
          setState(state);
          setPincode(pincode);
          setCountry(country);
        }
      });
    },
    [
      setCoordinates,
      setMarkerPosition,
      setAddress,
      setCity,
      setState,
      setPincode,
      setCountry,
    ]
  );

  useEffect(() => {
    const updateMapStyle = () => {
      if (window.innerWidth <= 768) {
        setMapStyle({ width: "100%", height: "200px" });
      } else {
        setMapStyle({ width: "250px", height: "200px" });
      }
    };

    updateMapStyle(); // Set the initial style
    window.addEventListener("resize", updateMapStyle);

    return () => {
      window.removeEventListener("resize", updateMapStyle);
    };
  }, []);

  if (loadError) return <div>Error loading maps</div>;
  return isLoaded ? (
    <div className="map-component">
      <GoogleMap
        // mapContainerStyle={{ width: "250px", height: "200px" }}
        mapContainerStyle={mapStyle}
        // className="google-map-styles"
        center={
          coordinates || { lat: 18.5369486821188, lng: 73.89341148301433 }
        }
        zoom={12}
        onClick={onMapClick}
        options={{
          disableDefaultUI: true,
          fullscreenControl: true,
        }}
      >
        {markerPosition && (
          <MarkerF
            position={markerPosition}
            draggable={true}
            onDragEnd={onMarkerDragEnd}
          />
        )}
      </GoogleMap>
      <p>
        {coordinates ? (
          <>
            Latitude: {coordinates.lat}
            <br />
            Longitude: {coordinates.lng}
          </>
        ) : (
          ""
        )}
      </p>
    </div>
  ) : (
    <div>Loading...</div>
  );
};

const AddressInput = ({
  setFormData,
  error,
  address,
  setAddress,
  city,
  setCity,
  state,
  setState,
  pincode,
  setPincode,
  country,
  setCountry,
  setCoordinates,
  setMarkerPosition,
  readOnly,
}) => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyB005xJuSALE7oAIxsJj0-AbW4orNeDKts",
    libraries: ["places"],
  });

  const autocompleteRef = useRef(null);

  useEffect(() => {
    console.log("Updated state values:", { city, state, pincode, country });
  }, [city, state, pincode, country]);

  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      setAddress(place.formatted_address);

      let newCity = "";
      let newState = "";
      let newPincode = "";
      let newCountry = "";

      place.address_components.forEach((component) => {
        const types = component.types;
        if (
          types.includes("locality") ||
          types.includes("sublocality") ||
          types.includes("sublocality_level_1")
        ) {
          newCity = component.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
          newState = component.long_name;
        }
        if (types.includes("postal_code")) {
          newPincode = component.long_name;
        }
        if (types.includes("country")) {
          newCountry = component.long_name;
        }
      });

      console.log("City:", newCity);
      console.log("State:", newState);
      console.log("Pincode:", newPincode);
      console.log("Country:", newCountry);

      setFormData((prevFormData) => ({
        ...prevFormData,
        city: newCity,
        state: newState,
        pinCode: newPincode,
        country: newCountry,
      }));

      setCoordinates({ lat, lng });
      setMarkerPosition({ lat, lng });
    }
  };

  if (loadError) return <div>Error loading maps</div>;
  return isLoaded ? (
    <>
      {!readOnly ? (
        <Autocomplete
          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
          onPlaceChanged={onPlaceChanged}
        >
          <InputField
            label="Address"
            id="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            error={error.address}
            readOnly={readOnly}
          />
        </Autocomplete>
      ) : (
        <InputField
          label="Address"
          id="address"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          error={error.address}
          readOnly={readOnly}
        />
      )}

      <div className="split-input space">
        <InputField
          label="City"
          id="city"
          placeholder="City"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          error={error.city}
          readOnly={readOnly}
        />
        <InputField
          label="State"
          id="state"
          placeholder="State"
          value={state}
          onChange={(e) => setState(e.target.value)}
          error={error.state}
          readOnly={readOnly}
        />
        <InputField
          label="Pincode"
          id="pincode"
          placeholder="Pincode"
          value={pincode}
          onChange={(e) => setPincode(e.target.value)}
          error={error.pincode}
          readOnly={readOnly}
        />
      </div>

      <InputField
        label="Country"
        id="country"
        placeholder="Country"
        value={country}
        onChange={(e) => setCountry(e.target.value)}
        error={error.country}
        readOnly={readOnly}
      />
    </>
  ) : (
    <div>Loading...</div>
  );
};

export { MapComponent, AddressInput };
