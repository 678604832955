import React, { useEffect, useState } from 'react'
import './footer.scss'
import { FaApple, FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { IoIosCall } from "react-icons/io";
import { IoLogoGooglePlaystore } from 'react-icons/io5';
import QrCode from './qr.png'
import { Link } from 'react-router-dom';
import { getCategoriesData } from '../../service/categoryService';

function Footer() {
    const [categoryData, setCategoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      fetchCategories();
    }, []);
  
  
  
  
    const fetchCategories = async () => {
      try {
        const response = await getCategoriesData();
        setCategoryData(response || []);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
  
    
  return (
    <div className='footer-container'>

        <div className='column'>
            <h2>
                About Us
            </h2>
            <p className='para'>Machinesonrent, the premier equipment rental marketplace, seamlessly connects customers in need of rental equipment with reliable providers, offering a comprehensive range of tools & machinery for various needs.</p>
            <div className='socials'>
                <p className='social-label'>Visit Us On:</p>
                <div className='social-holder'>
                    <FaFacebook size={25}/>
                    <FaInstagram size={25}/>
                </div>
            </div>
        </div>
        <div className='mid-row'>
            <div className='column'>
            <h2>
                Equipment
            </h2>
            <ul className='content'>
            {loading ? (
            Array.from({ length: 3 }).map((_, index) => (
              <React.Fragment key={index}>
                <li>
                  loading...
                </li>
                <hr />
              </React.Fragment>
            ))
          ) : (
            categoryData?.map((item) => (
              <React.Fragment >
                <li key={item.id}>
                  {item.name}
                </li>
               
              </React.Fragment>
            ))
          )}
            </ul>
            </div>
            <div className='column'>
            <h2>
                Find Us
            </h2>
            <ul className='content'>
                {/* <li>Home</li>
                <li>About Us</li>
                <li>Equipments</li> */}
                <li><Link to='/contact-us'>Contact Us</Link> </li>
                <li><Link to='/refund-policy'>Refund Policy</Link> </li>
                <li><Link to='/terms-and-conditions'>Terms & Conditions</Link> </li>

            </ul>
            </div>
        </div>

        <div className='column'>
            <h2 className='last-para'>
                Need Help Finding Equipment?
            </h2>
            <div className='socials'>
                <p className='contact'><IoIosCall/>+91 86052 85280</p>
                <p className='contact'><IoIosMail/>contact@machinesonrent.com</p>
            </div>
            <div className='text-qrcode'>
            <div className='top'>
                <div className='left'>
                    <h1>
                        Download App Now!

                    </h1>
                </div>
                {/* <div className='right'>
                    <img src={QrCode} alt='qr code'/>
                    <p>Scan to download<br/> IOS and android<br/> app </p>

                </div> */}
            </div>
            <div className='bottom'>
                <button className='app-btn'>
                    <IoLogoGooglePlaystore size={17}/>
                    <p>
                        GET IT ON <br/>
                        <span>Google Play</span>
                    </p>
                </button>
                <button className='app-btn'>
                    <FaApple size={17}/>
                    <p>
                        DOWNLOAD ON THE<br/>
                        <span>App Store</span>
                    </p>
                </button>
            </div>
        </div>
        </div>



          <div className='end-para'>
              <p>Copyrights © 2024 AK Global. All Rights Reserved</p>
              <p>
                  Created & Managed By{" "}
                  <a href="https://www.apagtechservices.com" target="_blank" rel="noopener noreferrer">
                      APAG Tech Services and Pvt. Ltd.
                  </a>
              </p>

        </div>


    </div>
  )
}

export default Footer