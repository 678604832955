import React, { useState, useRef, useEffect, useCallback, useContext } from "react";
import { MdEdit } from "react-icons/md";
import { FaEye, FaRegStar, FaStar } from "react-icons/fa";
import { MdOutlineMoreHoriz } from "react-icons/md";
import ToggleSwitch from '../toggle-switch/ToggleSwitch';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import './TableComponent.scss';
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import RatingModal from "../../../dashboard/reccently-visited/rating/rating-modal/RatingModal";
import axios from "axios";

import UserContext from "../../../context/userContext";
import { post } from "../../../APIHandler";
import { AiOutlineCaretRight } from "react-icons/ai";
import { addEquipmentRating, getEquipmentBySubscriptionId } from "../../../service/equipmentService";
import Tooltip from "../../../dashboard/common-components/tooltip/Tooltip";

const TableComponent = ({ columns, data, onView, onEdit, onToggleChange, onCheckboxChange, hideActions, itemsPerPage = 10, recentlyVisited = false, enableCollapsible = false, extraActions = false, subscriptionActions  }) => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext)

    const [expandedRows, setExpandedRows] = useState({});
    const [expandedTableRows, setExpandedTableRows] = useState({});
    const [equipmentName, setEquipmentName] = useState('');
    const rowActionsRefs = useRef([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [isRatingModalOpen, setRatingModalOpen] = useState(false);
    const [currentEquipmentId, setCurrentEquipmentId] = useState(null);
    const [expandedRowData, setExpandedRowData] = useState({});

    const handleRateClick = (equipmentId, name) => {
        setCurrentEquipmentId(equipmentId);
        setEquipmentName(name);
        setRatingModalOpen(true);
    };

    const handleRatingSubmit = async(ratingData) => {
        await addEquipmentRating(ratingData)
            .then(response => {
                setRatingModalOpen(false);
            })
            .catch(error => {
                console.error('Error submitting rating:', error);
            });
    };

    const formatCurrency = (value, currency = 'INR') => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value);
    };

    const formatDate = (date) => {
        return moment(date).format('DD/MM/YYYY');
    };

    const handleRowClick = async (rowIndex, subscriptionId) => {
        setExpandedRows((prevExpanded) => {
            const newExpandedRows = { ...prevExpanded, [rowIndex]: !prevExpanded[rowIndex] };

            Object.keys(newExpandedRows).forEach((key) => {
                if (parseInt(key) !== rowIndex) {
                    newExpandedRows[key] = false;
                }
            });

            return newExpandedRows;
        });


        // if (!expandedRows[rowIndex]) {
        //     try {
        //         const response = await axios.get(`${process.env.REACT_APP_API_URL}/Equipment/GetEquipmentBySubscriptionId?id=${subscriptionId}`);
        //         const rowData = Array.isArray(response.data) ? response.data : [response.data];
        //         setExpandedRowData((prevData) => ({
        //             ...prevData,
        //             [rowIndex]: rowData,
        //         }));

        //     } catch (error) {
        //         console.error("Error fetching data:", error);
        //     }
        // }
    };
    const handleSubTableRowClick = async (rowIndex, subscriptionId) => {
        setExpandedTableRows((prevExpanded) => {
            const newExpandedRows = { ...prevExpanded, [rowIndex]: !prevExpanded[rowIndex] };

            Object.keys(newExpandedRows).forEach((key) => {
                if (parseInt(key) !== rowIndex) {
                    newExpandedRows[key] = false;
                }
            });

            return newExpandedRows;
        });


        if (!expandedTableRows[rowIndex]) {
            try {
                const response = await getEquipmentBySubscriptionId(subscriptionId);
                const rowData = Array.isArray(response.data) ? response.data : [response.data];
                setExpandedRowData((prevData) => ({
                    ...prevData,
                    [rowIndex]: rowData,
                }));

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
    };





    const handleClickOutside = useCallback((event) => {
        const isClickInside = rowActionsRefs.current.some(ref => ref && ref.contains(event.target));
        if (!isClickInside) {
            setExpandedRows({});
        }
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    const getNestedValue = (obj, path) => {
        const value = path.split('.').reduce((value, key) => value && value[key], obj);

        if (Array.isArray(value) && path.includes('categories')) {
            const firstCategory = value[0]?.name || '';
            const additionalCount = value.length > 1 ? `+${value.length - 1}` : '';


            const tooltipContent = value.map(category => category.name).join(', ');

            return (
                <Tooltip content={tooltipContent}>

                        {firstCategory} {additionalCount}

                </Tooltip>
            );
        }

        return value;
    };

    const handleNavigate = (path, rowIndex) => {
        setTimeout(() => {
            setExpandedRows((prevExpanded) => ({
                ...prevExpanded,
                [rowIndex]: false
            }));
            navigate(path);
        }, 100);
    };

    const totalPages = Math.ceil(data?.length / itemsPerPage);
    const currentData = data?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };



    return (
        <div className="table-wrapper">
            <table className='equipment-table'>
                <thead>
                    <tr>
                    {currentData.length > 0 &&
                    (user?.role?.toLowerCase() === 'admin'
                        ? ""
                        : (enableCollapsible && <th></th>)
                    )
                }

                        {columns?.map((col) => (
                            <th key={col.accessor}
                            style={col.headerStyle || {}}>{col.Header}</th>
                        ))}
                        {!hideActions && <th className={"action"}><span>Actions</span></th>}
                    </tr>
                </thead>
                <tbody>
                    {currentData?.map((row, rowIndex) => (
                        <>
                            <tr key={rowIndex}>

                            {currentData.length > 0 &&

                            (user?.role?.toLowerCase() === 'admin'
                                ? ""
                                : (enableCollapsible && (
                                    <td>
                                        <span
                                            className="actions-data"
                                            onClick={() => handleSubTableRowClick(rowIndex, row.id)}
                                        >
                                            <AiOutlineCaretRight size={18} />
                                        </span>
                                    </td>
                                ))
                            )
                        }

                                {columns.map((col) => {
                                    let cellValue = getNestedValue(row, col.accessor);
                                    let cellClassName = "table-margin-left";

                                    if (col.format === 'currency') {
                                        cellValue = formatCurrency(cellValue);
                                        cellClassName = 'currency-cell';
                                    }

                                    if (col.format === 'date') {
                                        cellValue = formatDate(cellValue);
                                    }

                                    if (col.type === 'toggle') {
                                        return (
                                            <td key={col.accessor} className={cellClassName}>
                                                <ToggleSwitch
                                                    id={`toggle-${rowIndex}`}
                                                    checked={cellValue}
                                                    onChange={() => onToggleChange(rowIndex, col.accessor)}
                                                />
                                            </td>
                                        );
                                    }

                                    if (col.type === 'checkbox') {
                                        return <td key={col.accessor} className={cellClassName}>
                                            <input
                                                type="checkbox"
                                                checked={cellValue}
                                                onChange={() => onCheckboxChange(rowIndex, col.accessor)}
                                            />
                                        </td>;
                                    }

                                    return (
                                        <td key={col.accessor} className={cellClassName}>
                                            {col.Cell ? col.Cell({ value: cellValue, row: row }) : <span>{cellValue}</span>}
                                        </td>
                                    );

                                })}


                                {!hideActions && (
                                <>
                                    <td>
                                    <span
                                        className="actions-data"
                                        onClick={() => handleRowClick(rowIndex)}
                                        ref={el => (rowActionsRefs.current[rowIndex] = el)}
                                    >
                                        <MdOutlineMoreHoriz size={18} />

                                        {expandedRows[rowIndex] && (
                                        <>

                                            {subscriptionActions ? (
                                            <div className="row-actions">
                                                <button onClick={() => subscriptionActions.onView(row.id)}>
                                                <FaEye size={15} /> View
                                                </button>
                                                <button onClick={() => subscriptionActions.onEdit(row.id)}>
                                                <MdEdit size={15} /> Edit
                                                </button>
                                            </div>
                                            ) : (

                                            <>
                                                {recentlyVisited ? (
                                                <div className="row-actions">
                                                    <button onClick={() => handleRateClick(row.id, row.name)}>
                                                    <FaRegStar size={15} /> RATE
                                                    </button>
                                                </div>
                                                ) : (
                                                <div className="row-actions">
                                                    <button onClick={() => handleNavigate(`/dashboard/equipments-list/view/${row.id}`, rowIndex)}>
                                                    <FaEye size={15} /> View
                                                    </button>
                                                    {user?.role?.toLowerCase() !== 'admin' && (
                                                    <button onClick={() => handleNavigate(`/dashboard/equipments-list/edit/${row.id}`, rowIndex)}>
                                                        <MdEdit size={15} /> Edit
                                                    </button>
                                                    )}
                                                </div>
                                                )}
                                            </>
                                            )}
                                        </>
                                        )}
                                    </span>
                                    </td>
                                </>
                                )}

                            </tr>
                            {expandedTableRows[rowIndex] && (
                                <tr className="expanded-row">
                                    <td colSpan={columns.length + (hideActions ? 0 : 1)}>
                                        {Array.isArray(expandedRowData[rowIndex]) ? (
                                            <table className="expanded-table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th></th>
                                                        <th>Equipment Name</th>
                                                        <th>Model Number</th>
                                                        {/* <th>Year of Manufacturing</th> */}
                                                        <th>Working Hours</th>
                                                        {/* <th>Rental Value</th>
                                                        <th>Discount</th> */}
                                                        <th>Final Rental Value</th>
                                                        <th>Category</th>
                                                        <th>Location</th>
                                                        {/* <th></th> */}
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {expandedRowData[rowIndex].flatMap((equipmentArray, arrayIdx) =>
                                                        equipmentArray.data.map((equipment, idx) => (
                                                            <tr key={`${arrayIdx}-${idx}`}>
                                                                <td></td>
                                                                <td></td>
                                                                <td>{equipment.name}</td>
                                                                <td>{equipment.modelNumber}</td>
                                                                {/* <td>{equipment.yearOfMfg}</td> */}
                                                                <td>{equipment.noOfWorkingHrs}</td>
                                                                {/* <td>{equipment.rentalValue}</td>
                                                                <td>{equipment.discount}</td> */}
                                                                <td>{equipment.finalRentalValue}</td>
                                                                <td>{equipment.category}</td>
                                                                <td>{`${equipment.city}, ${equipment.state}, ${equipment.country}`}</td>
                                                                <td>{equipment.status}</td>
                                                            </tr>
                                                        ))
                                                    )}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <span>Loading...</span>
                                        )}
                                    </td>
                                </tr>
                            )}



                        </>
                    ))}
                </tbody>
            </table>
            <RatingModal
                show={isRatingModalOpen}
                onClose={() => setRatingModalOpen(false)}
                onSubmit={handleRatingSubmit}
                equipmentId={currentEquipmentId}
                userId={user?.loggedInUserId}
                equipmentName={equipmentName}
                userName="Supplier"
            />
            <div className="pagination">
                <button
                    className="page-nav"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                >
                    <FiChevronLeft />
                </button>
                {[...Array(totalPages)].map((_, index) => (
                    <button
                        key={index}
                        className={index + 1 === currentPage ? 'active' : ''}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </button>
                ))}
                <button
                    className="page-nav"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                >
                    <FiChevronRight />
                </button>
            </div>
        </div>
    );
};

export default TableComponent;
