import React from 'react';
import './popularlocations.scss';
import cities from '../../Constants/Cities';
import { useCity } from '../../context/LocationContext';

function PopularLocations() {
    const { selectCity } = useCity();

    const handleCityClick = (city) => {
        selectCity(city);

        const targetElement = document.getElementById(`location-map`);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='p-location-container'>
            <h2>Popular Locations</h2>
            <p className='para'>
                Find reliable machines for rent in your city and complete your projects efficiently. Explore a wide range of industrial, construction, and agricultural equipment from trusted providers across these top locations. No matter where you are, MachinesOnRent connects you with the best rental options to power your projects.
            </p>

            <div className="cities-grid">
                {cities.slice(0, 20).map((city) => (
                    <div 
                        key={city.id} 
                        className="city-card" 
                        onClick={() => handleCityClick(city)} 
                    >
                        {city.name}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PopularLocations;
