import React, { useState, useEffect, useContext } from 'react';
import { FiChevronLeft, FiChevronRight, FiEdit3 } from 'react-icons/fi';
import './equipmentcategory.scss';
import { LoadingContext } from '../../../context/LoadingContext';
import { addCategory, fetchCategories, toggleCategoryStatus, updateCategory } from '../../../service/EquipmentCategoryService';
import { toast } from 'sonner';
import { CiSearch } from 'react-icons/ci';

function EquipmentCategory() {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newCategory, setNewCategory] = useState({ type: '', subType: '', active: true });
  const [editCategory, setEditCategory] = useState(null);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [filterOption, setFilterOption] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 16;

  useEffect(() => {
    async function loadCategories() {
      try {
        showLoader();
        const categoryData = await fetchCategories();
        setCategories(categoryData);
        setFilteredCategories(categoryData);
        hideLoader();
      } catch (error) {
        console.error('Error loading categories:', error);
        hideLoader();
      }
    }
    loadCategories();
  }, []);

  useEffect(() => {
    const filtered = categories.filter((category) => {
      const matchesSearch =
        category.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
        category.subType.toLowerCase().includes(searchQuery.toLowerCase());

      const matchesFilter =
        filterOption === 'all' ||
        (filterOption === 'active' && category.active) ||
        (filterOption === 'inactive' && !category.active);

      return matchesSearch && matchesFilter;
    });

    setFilteredCategories(filtered);
    setCurrentPage(1); // Reset to the first page when filters change
  }, [searchQuery, filterOption, categories]);

  const totalPages = Math.ceil(filteredCategories.length / itemsPerPage);
  const currentCategories = filteredCategories.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSave = async () => {
    try {
      showLoader();
      if (editCategory) {
        await updateCategory({
          id: editCategory.id,
          type: newCategory.type,
          subType: newCategory.subType,
          active: newCategory.active,
        });
        setCategories(categories.map((cat) =>
          cat.id === editCategory.id ? { ...newCategory, id: editCategory.id } : cat
        ));
        toast.success('Category Updated Successfully');
      } else {
        const newCat = await addCategory(newCategory);
        setCategories([...categories, { ...newCategory, id: newCat.id }]);
      }

      setShowModal(false);
      setNewCategory({ type: '', subType: '', active: true });
      setEditCategory(null);
      hideLoader();
    } catch (error) {
      console.error('Error saving category:', error);
      hideLoader();
    }
  };

  const handleEditClick = (category) => {
    setEditCategory(category);
    setNewCategory({
      type: category.type,
      subType: category.subType,
      active: category.active,
    });
    setShowModal(true);
  };

  const handleToggleStatus = async (category) => {
    try {
      showLoader();
      const updatedStatus = !category.active;
      const response = await toggleCategoryStatus(category);

      if (response) {
        setCategories(
          categories.map((cat) =>
            cat.id === category.id ? { ...cat, active: updatedStatus } : cat
          )
        );
        toast.success(`Category ${updatedStatus ? 'activated' : 'deactivated'} successfully`);
      }
      //  else {
        // toast.error('Failed to update category status');
      // }
      hideLoader();
    } catch (error) {
      console.error('Error toggling category status:', error);
      // toast.error('An error occurred while updating the status');
      hideLoader();
    }
  };

  const handleAddClick = () => {
    setNewCategory({ type: '', subType: '', active: true });
    setShowModal(true);
  };

  const goToNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const goToPreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
  const goToPage = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="equipment-category-container">
      <div className="head">
        <h3>Equipment Categories</h3>
        <button className="add-category-btn" onClick={handleAddClick}>Add Category</button>
      </div>



      <div className="filters">
        <div className="search-bar">
          <CiSearch />
          <input
            type="text"
            placeholder="Search categories..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <div className="dropdown-filter">
          <label>Status</label>
          <select value={filterOption} onChange={(e) => setFilterOption(e.target.value)}>
            <option value="all">All</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
        </div>
      </div>


      <table className="equipment-category-table">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Category Type</th>
            <th>Sub Type</th>
            <th style={{ textAlign: 'center' }}>Active</th>
            <th style={{ textAlign: 'right' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentCategories.map((category, index) => (
            <tr key={category.id}>
              <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
              <td>{category.type}</td>
              <td>{category.subType}</td>
              <td style={{ textAlign: 'center' }}>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={category.active}
                    onChange={() => handleToggleStatus(category)}
                  />
                  <span className="slider round"></span>
                </label>
              </td>
              <td style={{ textAlign: 'right' }}>
                <button onClick={() => handleEditClick(category)} className="edit-btn">
                  <FiEdit3 size={15} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        <button
          className="page-nav"
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
        >
          <FiChevronLeft />
        </button>
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            className={index + 1 === currentPage ? 'active' : ''}
            onClick={() => goToPage(index + 1)}
          >
            {index + 1}
          </button>
        ))}
        <button
          className="page-nav"
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
        >
          <FiChevronRight />
        </button>
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>{editCategory ? 'Edit Category' : 'Add New Category'}</h2>

            <label>
              Category Type:
            </label>
              <input
                type="text"
                value={newCategory.type}
                onChange={(e) => setNewCategory({ ...newCategory, type: e.target.value })}
              />

            <label >
              Sub Type:
            </label>
              <input
                type="text"
                value={newCategory.subType}
                onChange={(e) => setNewCategory({ ...newCategory, subType: e.target.value })}
              />

            <label className="switch">
              Active:
              <input
                type="checkbox"
                checked={newCategory.active}
                onChange={(e) => setNewCategory({ ...newCategory, active: e.target.checked })}
              />
               <span className="slider round"></span>
            </label>
            <div className='action-btns'>
              <button onClick={handleSave}>
                {editCategory ? 'Save Changes' : 'Add Category'}
              </button>
              <button onClick={() => setShowModal(false)}>Cancel</button>

            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EquipmentCategory;
