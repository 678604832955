import { get, post } from "../APIHandler";

export const GetSubscriptions = async () => {
    try {
        const subscriptionData = await get('/Subscription/GetSubscriptions');
        console.log("Service Response ========>", subscriptionData);
        return subscriptionData;
    } catch (error) {
        console.error('Error fetching equipment data', error);
        throw error;
    }
};

export const GetPurchasedSubscriptions = async (id) => {
    try {
        const response = await get(`/Subscription/GetPurchasedSubscriptionsByUserId?id=${id}`);
        console.log("Service Response ========>", response);
        if (response) {
            return response;
        } else {
            throw new Error("Failed to verify payment");
        }
    } catch (error) {
        console.error('Error fetching equipment data', error);
        throw error;
    }
};

export const addNewUserPurchasedSubscription = async (subsDetails) => {
    try {
        const response = await post('/Subscription/AddUserPurchaseSubs', subsDetails);
        return response;
    } catch (error) {
        console.error('Error adding equipment.', error);
        throw error;
    }
};

export const addNewSubscription = async (formDataToSend) => {
    try {
        const response = await post('/Subscription/AddNewSubscription', formDataToSend);
        return response;
    } catch (error) {
        console.error('Error adding equipment.', error);
        throw error;
    }
};

export const GetSubscriptionById = async (subscriptionId) => {
    try {
        const response = await get(`/Subscription/GetSubscriptionById?id=${subscriptionId}`);
        return response;
    } catch (error) {
        console.error('Error fetching subscription data', error);
        throw error;
    }
};

export const UpdateSubscriptionPlan = async (updatedData) => {
    try {
      const response = await post('/Subscription/UpdateSubscriptionPlan', updatedData);
      return response;
    } catch (error) {
      console.error('Error updating equipment:', error);
      throw error;
    }
  };