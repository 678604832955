import React, { useContext, useEffect, useRef, useState } from 'react';
import './tempheader.scss';
import { IoIosMail } from "react-icons/io";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaUser } from "react-icons/fa";
import { FaGear } from "react-icons/fa6";
import { VscSignOut } from "react-icons/vsc";
import UserContext from '../../../context/userContext';
import { FiMenu } from "react-icons/fi";
import { BiTimeFive } from "react-icons/bi";
import { FaCrown } from "react-icons/fa";
import MORLogo from './LogoMOR.png'

function TempHeader({ handleLoginClick }) {
  const navigate = useNavigate();
  const context = useContext(UserContext);
  const { user, setUser, handleShowSidebar, showSidebar } = context;

  const [isOpen, setIsOpen] = useState(false);
  const userInfoRef = useRef(null);
  const menuIconRef = useRef(null);

  const location = useLocation();
  const pathname = location.pathname;


  const handleClickOutside = (event) => {
    if (userInfoRef.current && !userInfoRef.current.contains(event.target)) {
      setIsOpen(false); // Close the dropdown
    }
  };

  useEffect(() => {

    document.addEventListener('mousedown', handleClickOutside);
    return () => {

      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleUserInfoClick = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    setUser(null);
    navigate('/');
  };

  const showLogo = pathname === '/dashboard' || pathname.startsWith('/dashboard/');

  return (
    <div className='tempheader-container'>
      <div className='left'>
        {user && showLogo ? (
          <Link to='/'>
            <div className='logo-mor'>

            <img src={MORLogo} alt='MOR Logo' className='mor-img'/>

                <div className="logo">
                  <h2 className='machines'>Machines</h2>
                  <h3 className='onrent'><i>On</i> Rent</h3>
                </div>

            </div>


          </Link>
        ) : (
          <span>
            <IoIosMail size={21} /> contact@machinesonrent.com
          </span>
        )}
        {showLogo ? (
          <FiMenu
            // ref={menuIconRef}
            className='menu-icon'
            size={21}
            onClick={handleShowSidebar}
          />
        ) : null}
      </div>
      {user ? (
        <>
          <div className='user-info' onClick={handleUserInfoClick} ref={userInfoRef}>
            <FaUser />
            <div className='user-name'>
              <p>{user.loggedUserName}</p>
              <span>{user.role}</span>
              {isOpen && (
                <ul className='user-dropdown' >
                  <li>
                    <Link to="/dashboard/profile"><FaUser size={14} color='#000' />My Profile</Link>
                  </li>
                  <li>
                  <Link to="/dashboard/equipments-list">
                    <FaGear size={14} color="#000" />
                    {user?.role?.toLowerCase() === 'admin' ? ' Equipments' : ' My Equipments'}
                  </Link>

                  </li>
                  {user?.role?.toLowerCase() === 'admin' ? "" : (
                    <>
                      <li>
                        <Link to="/dashboard/recently-visited"><BiTimeFive size={14} color='#000' />Recently Viewed</Link>
                      </li>
                      <li>
                        <Link to="/dashboard/add-equipment"><FaGear size={14} color='#000' />Add Equipment</Link>
                      </li>
                    </>
                  )}
                  <li>
                    <Link to="/dashboard/subscription-admin"><FaCrown size={14} color='#000' />{user?.role?.toLowerCase() === 'admin' ? ' Subscriptions' : ' My Subscriptions'}</Link>
                  </li>
                  <hr />
                  <li onClick={handleLogout}><VscSignOut size={14} color='#000' /> Logout</li>
                </ul>
              )}
            </div>
          </div>
        </>
      ) : (
        <button onClick={handleLoginClick}>Login In/Sign Up</button>
      )}
    </div>
  );
}

export default TempHeader;
