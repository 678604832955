import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";

export const RecentlyvisitedColumn = [
  { Header: 'Equi. Name', accessor: 'name' },
  { Header: 'Model', accessor: 'modelNumber' },
  {
    Header: "Category",
    accessor: "categories",
    Cell: ({ value }) => {
      if (!Array.isArray(value) || value.length === 0) return "N/A";
      return (
        <div>
          {value.length > 0 ? `${value[0]?.name} ${value.length - 1 > 0 ? `+${value.length - 1}` : ""}` : "N/A"}
        </div>
      );
    },
  },
  { Header: 'Sub Category', accessor: 'subCategory' },
  { Header: 'Region', accessor: 'region' },
  { Header: 'Final Value', accessor: 'finalRentalValue', format: 'currency' },

  {
    Header: 'Ratings',
    accessor: 'equipmentRatings',
    Cell: ({ value }) => {
      if (!value || value.length === 0) return "No Ratings";

      const averageRating = value.reduce((acc, rating) => acc + rating.starRating, 0) / value.length;

      const fullStars = Math.floor(averageRating);
      const halfStar = averageRating % 1 !== 0;
      const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

      return (
        <div style={{display: "flex", gap: "0.1rem"}}>
          {Array(fullStars)
            .fill()
            .map((_, i) => (
              <FaStar key={i} color="#F2800A" />
            ))}

          {halfStar && <FaStarHalfAlt color="#F2800A" />}

          {Array(emptyStars)
            .fill()
            .map((_, i) => (
              <FaRegStar key={i + fullStars} color="gray" />
            ))}
        </div>
      );
    },
  }
];
