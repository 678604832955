import { post } from "../APIHandler";

export const updateUserDetail = async(profileData) =>{
    try {
        const response = await post('/User/UpdateUserDetail',profileData);

        return response;


    } catch (error) {
        console.error(error);
        throw error;

    }
}