import React, { useContext, useEffect, useState } from "react";
import "./profile.scss";

import InputField from "../common-components/inputs/InputField";
import Button from "../common-components/buttons/Button";
import Checkbox from "../common-components/checkboxes/Checkbox";
import UploadImage from "../../components/CommonComponents/upload-image/UploadImage";
import { updateUserDetail } from "../../service/profileService";
import { FiChevronLeft } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import UserContext from "../../context/userContext";
import { GetCustomerById } from "../../service/customerService";
import { LoadingContext } from "../../context/LoadingContext";

function Profile({ customerData, handleSave, view }) {
  const navigate = useNavigate();
  const [images, setImages] = useState(Array(4).fill(null));
  const { user } = useContext(UserContext);
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const [formData, setFormData] = useState({
    id: "",
    firstName: "",
    mobileNumber: "",
    lastName: "",
    panNumber: "",
    email: "",
    altMobileNumber: "",
    addressLine1: "",
    city: "",
    state: "",
    pincode: "",
    organizationName: "",
    cinNumber: "",
    gstNumber: "",
    dateOfBirth: "",
    role: "",
    userName: "",
    normalizedUserName: "",
    fullName: "",
    organizationId: "",
    organization: {
      name: "",
      designation: "",
      cinNumber: "",
      websiteUrl: "",
      companyEmail: "",
    },
  });

  const initialFormData = {
    id: "",
    firstName: "",
    mobileNumber: "",
    lastName: "",
    panNumber: "",
    email: "",
    altMobileNumber: "",
    addressLine1: "",
    city: "",
    state: "",
    pincode: "",
    organizationName: "",
    cinNumber: "",
    gstNumber: "",
    dateOfBirth: "",
    role: "",
    userName: "",
    normalizedUserName: "",
    fullName: "",
    organizationId: "",
    organization: {
      name: "",
      designation: "",
      cinNumber: "",
      websiteUrl: "",
      companyEmail: "",
    },
  };

  useEffect(() => {
    if (customerData) {
      return;
    }

    const fetchUserData = async () => {
      // showLoader();
      try {
        const response = await GetCustomerById(user.loggedInUserId);

        if (response) {
          setFormData((prevData) => ({
            ...prevData,
            ...response,
            mobileNumber: response.phoneNumber,
            organization: response.organization || {
              name: "",
              designation: "",
              cinNumber: "",
              websiteUrl: "",
              companyEmail: "",
            },
          }));
        }
        // hideLoader();
      } catch (error) {
        console.error(error);
        // hideLoader();
      }
    };

    fetchUserData();
  }, [customerData, user.loggedInUserId]);

  useEffect(() => {
    if (customerData) {
      setFormData((prevData) => ({
        ...prevData,
        ...customerData,
        mobileNumber: customerData.phoneNumber,
        organization: customerData.organization || {
          name: "",
          designation: "",
          cinNumber: "",
          websiteUrl: "",
          companyEmail: "",
        },
      }));
    }
  }, [customerData]);

  const handleImageSelected = (index, image) => {
    setImages((prevImages) => [
      ...prevImages.slice(0, index),
      image,
      ...prevImages.slice(index + 1),
    ]);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    if (id === "mobileNumber" || id === "altMobileNumber") {
      const digitOnlyValue = value.replace(/\D/g, "");
      if (digitOnlyValue.length <= 10) {
        setFormData({ ...formData, [id]: digitOnlyValue });
      }
    } else if (id.startsWith("organization.")) {
      const key = id.split(".")[1];
      setFormData((prevData) => ({
        ...prevData,
        organization: { ...prevData.organization, [key]: value },
      }));
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataToSend = {
      ...formData,
      ...(customerData?.id && { id: customerData.id }),
    };
    try {
      if (customerData) {
        await handleSave(formDataToSend);
        toast.success("Profile Updated Successfully!", { variant: "success" });
      } else {
        const response = await updateUserDetail(formData);
        toast.success("Profile Updated Successfully!", { variant: "success" });
      }
    } catch (error) {
      toast.error("Failed to save subscription.", { variant: "error" });
      console.error("There was an error submitting the form!", error);
    }
  };

  return (
    <div className="profile-container">
      <form onSubmit={handleSubmit}>

        {(handleSave || view) && (
          <button className="back-btn" onClick={() => navigate(-1)}>
            <FiChevronLeft size={16} />
            <p>Back to Results</p>
          </button>
        )}
        {/* //  : ''} */}

        <h2>
          {handleSave ? "Edit Profile" : view ? "View Profile" : "Profile"}
        </h2>
        <div className="info-field">
          {/* <UploadImage onImageSelected={handleImageSelected} /> */}
          <div className="info-container">
            <div className="split-input">
              <InputField
                label="First Name"
                id="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
              <InputField
                label="Mobile Number"
                id="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
              />
            </div>
            <div className="split-input">
              <InputField
                label="Last Name"
                id="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
              <InputField
                label="PAN Number"
                id="panNumber"
                value={formData.panNumber}
                onChange={handleChange}
              />
            </div>
            <InputField
              label="Preffered Name"
              id="normalizedUserName"
              value={formData.firstName}
              onChange={handleChange}
            />
            <InputField
              label="Date of Birth"
              id="dateOfBirth"
              type="date"
              value={formData.dateOfBirth}
              onChange={handleChange}
            />
            <div className="check-bar">
              {/* <Checkbox label="Tick if Organization Details are Available." /> */}
              {/* <hr /> */}
            </div>
          </div>
        </div>

        {/* <InputField
          className="half"
          label="Organization Name"
          id="organizationName"
          value={formData.organizationName}
          onChange={handleChange}
        />
        <InputField
          className="half"
          label="Organization Designation"
          id="organization.designation"
          value={formData.organization.designation}
          onChange={handleChange}
        />
        <InputField
          className="half"
          label="Organization Website"
          id="organization.websiteUrl"
          value={formData.organization.websiteUrl}
          onChange={handleChange}
        />
        <InputField
          className="half"
          label="Organization Company Email"
          id="organization.companyEmail"
          value={formData.organization.companyEmail}
          onChange={handleChange}
        />
        <InputField
          className="half"
          label="Role"
          id="role"
          value={formData.role}
          onChange={handleChange}
        />
        <InputField
          className="half"
          label="Organization ID"
          id="organizationId"
          value={formData.organizationId}
          onChange={handleChange}
        />
        <InputField
          className="half"
          label="CIN Number"
          id="cinNumber"
          value={formData.cinNumber}
          onChange={handleChange}
        />
        <InputField
          className="half"
          label="GST Number"
          id="gstNumber"
          value={formData.gstNumber}
          onChange={handleChange}
        /> */}

        <hr />

        <InputField
          label="Address"
          id="addressLine1"
          value={formData.addressLine1}
          onChange={handleChange}
        />
        <div className="profile-input-field">
          <InputField
            label="City"
            id="city"
            value={formData.city}
            onChange={handleChange}
          />
          <InputField
            label="State"
            id="state"
            value={formData.state}
            onChange={handleChange}
          />
        </div>
        <div className="profile-input-field">
          <InputField
            label="Pincode"
            id="pincode"
            value={formData.pincode}
            onChange={handleChange}
          />
          <InputField
            label="Email"
            id="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="profile-input-field">
          <InputField
            label="Mobile Number"
            id="mobileNumber"
            value={formData.mobileNumber}
            onChange={handleChange}
          />
          <InputField
            label="Alt. Mobile No."
            id="altMobileNumber"
            value={formData.altMobileNumber}
            onChange={handleChange}
          />
        </div>

        <hr />

        <div className="btns-container">
          <Button type="submit" className="save-btn" label="Save" />
          <Button
            type="button"
            className="cancel-btn"
            label="Cancel"
            onClick={() => setFormData(initialFormData)}
          />
        </div>
      </form>
    </div>
  );
}

export default Profile;
