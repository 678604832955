import { post } from "../APIHandler";

export const loginService = async (userName, password) => {
    try {
        const response = await post('/Login/Login', { userName, password });
        return response;
    } catch (error) {
        console.error('Error logging in', error);
        throw error;
    }
};

export const signUpUser = async (formData) => {
    try {
        const response = await post('/Login/SignUp', formData);
        return response;
    } catch (error) {
        console.error('Error in registering new user ERROR:', error);
        throw error;
    }
};

export const generateOtpForPhoneNumber = async (phoneNumber) => {
    try {
        const response = await post('/Verification/GenerateOtpForPhonenumberVerification', { phoneNumber });
        return response;
    } catch (error) {
        console.error('Error generating OTP:', error);
        throw error;
    }
};


export const verifyOtp = async (otpData, otp) => {
    try {
        const response = await post('/Verification/VerifyOtp', {
            id: otpData.id,
            otp,
            securityStamp: otpData.securityStamp
        });
        return response;
    } catch (error) {
        console.error('Error verifying OTP:', error);
        throw error;
    }
};

export const ForgotPasswordOTP = async (userName) => {
    try {
        const response = await post('/Login/ForgotPassword', { userName });
        console.log(response)
        if (!response || !response.data) {
            throw new Error('Unexpected response from the server');
        }
        return response;
    } catch (error) {
        console.log(error)
        const errorMessage = error.response?.data?.message || 'An error occurred while sending OTP.';
        console.error('Service Error:', errorMessage);
        throw new Error(errorMessage);
    }
};



export const resetPassword = async (userName, password) => {
    try {
        const response = await post('/Login/ResetPassword', { userName, password });
        return response;
    } catch (error) {
        console.error('Error resetting password:', error);
        throw error;
    }
};
