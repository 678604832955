import React, { useContext, useEffect, useState } from 'react';
import './equipmenttype.scss';
import { FiEdit3 } from 'react-icons/fi';
import { LoadingContext } from '../../../context/LoadingContext';
import { addCategory, fetchCategories, updateCategory } from '../../../service/EquipmentCategoryService';
import { addEquipmentType, fetchEquipmentTypes, updateEquipmentType } from '../../../service/categoryService';
import { toast } from 'sonner';

function EquipmentType() {
  const [categories, setCategories] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [newCategory, setNewCategory] = useState({ name: '', colorCode: '', active: true });
  const [editCategory, setEditCategory] = useState(null);
  const { showLoader, hideLoader } = useContext(LoadingContext);

  useEffect(() => {
    async function loadCategories() {
      try {
        showLoader();
        const data = await fetchEquipmentTypes();
        setCategories(data);
        hideLoader();
      } catch (error) {
        console.error('Error fetching category data:', error);
        hideLoader();
      }
    }
    loadCategories();
  }, []);

  const handleSave = async () => {
    try {
      showLoader();

      if (editCategory) {
        const response = await updateEquipmentType({
          id: editCategory.id,
          name: newCategory.name,
          colorCode: newCategory.colorCode,
          active: newCategory.active,
          deleted: false
        });


        setCategories(categories.map(cat =>
          (cat.id === editCategory.id ? { ...newCategory, id: editCategory.id } : cat)
        ));

        toast.success('Equipment Type Updated Successfully');

      } else {
        const response = await addEquipmentType({
          name: newCategory.name,
          colorCode: newCategory.colorCode,
          active: true
        });

        setCategories([...categories, { ...newCategory, id: response.id }]);
      }

      setShowModal(false);
      setNewCategory({ name: '', colorCode: '', active: true });
      setEditCategory(null);
      hideLoader();
    } catch (error) {
      console.error('Error saving category:', error);
      hideLoader();
    }
  };

  const handleEditClick = (category) => {
    setEditCategory(category);
    setNewCategory({
      name: category.name,
      colorCode: category.colorCode,
      active: category.active
    });
    setShowModal(true);
  };

  const handleAddClick = () => {
    setNewCategory({ name: '', colorCode: '', active: true });
    setShowModal(true);
  };

  return (
    <div className="equipment-type-container">
      <div className="head">
        <h3>Equipment Types</h3>
        <button className="add-type-btn" onClick={handleAddClick}>Add Category</button>
      </div>

      <table className="equipment-type-table">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Category Name</th>
            <th>Color Code</th>
            <th style={{ textAlign: 'right' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category, index) => (
            <tr key={category.id}>
              <td>{index + 1}</td>
              <td>{category.name}</td>
              <td style={{ textAlign: 'right' }}>
                <div className="color-container" style={{ justifyContent: 'right' }}>
                  <div
                    className="color-box"
                    style={{ backgroundColor: category.colorCode || '#ccc' }}
                  ></div>
                  <p>{category.colorCode}</p>
                </div>
              </td>
              <td style={{ textAlign: 'right' }}>
                <button onClick={() => handleEditClick(category)} className="edit-btn">
                  <FiEdit3 size={15} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>{editCategory ? 'Edit Category' : 'Add New Category'}</h2>

            <label>
              Category Name:
              <input
                type="text"
                value={newCategory.name}
                onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
              />
            </label>

            <div className="picker-container">
              <input
                type="text"
                value={newCategory.colorCode}
                onChange={(e) => setNewCategory({ ...newCategory, colorCode: e.target.value })}
                placeholder="#000000"
              />
              <input
                type="color"
                value={newCategory.colorCode}
                onChange={(e) => setNewCategory({ ...newCategory, colorCode: e.target.value })}
                className="color-picker"
              />
            </div>

            <button onClick={handleSave}>
              {editCategory ? 'Save Changes' : 'Add Category'}
            </button>
            <button onClick={() => setShowModal(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default EquipmentType;
