import { get, post } from "../APIHandler"


export const getUsersList = async() =>{
    try {
        const response = await get('/User/GetUsersList');

        return response.data;

    } catch (error) {
        console.error("error fetching customer list", error);
        throw error;
    }
}

export const getSupplierList = async() =>{
    try {
        const response = await get('/Equipment/getallequipments');

        return response.data;

    } catch (error) {
        console.error("error fetching customer list", error);
        throw error;
    }
}


export const GetCustomerById = async (customerId) => {
    try {
        const response = await get(`/User/GetUserDetails?id=${customerId}`);
        return response;
    } catch (error) {
        console.error('Error fetching customer data', error);
        throw error;
    }
};

export const UpdateCustomerData = async (updatedData) => {
    try {
      const response = await post('/Customer/UpdateCustomer', updatedData);
      return response;
    } catch (error) {
      console.error('Error updating Customer:', error);
      throw error;
    }
  };