import React, { useContext, useEffect, useRef, useState } from 'react';

import { CiSearch } from 'react-icons/ci';

import CustomDropdown from '../../components/CommonComponents/custom-dropdown/CustomDropdown';
import { Link, useNavigate } from 'react-router-dom';
import { equipmentColumns } from '../../components/CommonComponents/TableComponent/EntityTables/EquipmentTblColumns';
import TableComponent from '../../components/CommonComponents/TableComponent/TableComponent';
import UserContext from '../../context/userContext.js';
import { getEquipmentData, GetOwnerEquipments } from '../../service/equipmentService.js';
import { LoadingContext } from '../../context/LoadingContext.js';
import { getCategoryDropdownOptions } from '../../service/categoryService.js';
import { getEquipmentTypeDropDown } from '../../service/dropDownService.js';

const flattenData = (data) => {
  return data.map(item => ({
    ...item,
    subCategory: item.equipmentType?.subType || ''
  }));
};

function EquipmentList() {
  const context = useContext(UserContext);
  const { user } = context;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const dropdownRef = useRef(null);

  const [equipmentsData, setEquipmentsData] = useState([]);
  const [filteredEquipments, setFilteredEquipments] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const { showLoader, hideLoader } = useContext(LoadingContext);

  const navigate = useNavigate();

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    fetchEquipments();
    fetchCategories();
    fetchSubCategories();
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [user]);

  const fetchEquipments = async () => {
    try {
      showLoader();
      let data;
      if (user.role.toLowerCase() === 'admin') {
        data = await getEquipmentData();
      } else {
        data = await GetOwnerEquipments({
          supplierId : user.loggedInUserId
        });
      }

      const flattenedData = flattenData(data.data);
      setEquipmentsData(flattenedData);
      setFilteredEquipments(flattenedData);
    } catch (err) {
      setError(err.message);
    } finally {
      hideLoader();
    }
  };

  console.log(equipmentsData)

  const fetchCategories = async () => {
    showLoader();
    try {
      const response = await getCategoryDropdownOptions();
      setCategories(response);
    } catch (error) {
      setError(error.message);
    } finally {
      hideLoader();
    }
  };

  const fetchSubCategories = async () => {
    showLoader();
    try {
      const response = await getEquipmentTypeDropDown();
      setSubCategories(response);
    } catch (error) {
      setError(error.message);
    } finally {
      hideLoader();
    }
  };

  const filterEquipments = () => {
    let filteredData = equipmentsData;

    if (searchQuery.trim() !== '') {
      filteredData = filteredData.filter((equipment) =>
        equipment.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        equipment.modelNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        equipment.region?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        equipment.subCategory.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (selectedCategory) {
      filteredData = filteredData.filter(equipment => equipment.category === selectedCategory);
    }

    if (selectedSubCategory) {
      filteredData = filteredData.filter(equipment => equipment.subCategory === selectedSubCategory);
    }

    setFilteredEquipments(filteredData);
  };

  const handleSelectCategory = (option) => {
    setSelectedCategory(option || '');
  };

  const handleSelectSubCategory = (option) => {
    setSelectedSubCategory(option || '');
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleView = (equipment) => {
    navigate(`/dashboard/view-equipment/${equipment.id}`);
  };

  const handleEdit = (equipment) => {
    navigate(`/dashboard/edit-equipment/${equipment.id}`);
  };

  return (
    <div className='list-container'>
      <h3>EQUIPMENT LIST</h3>
      <div className='user-actions'>
        <div className='left'>
          <div className='search-bar'>
            <CiSearch />
            <input type='text' placeholder='Search...' onChange={handleInputChange} />
          </div>

          <CustomDropdown
            options={categories?.map(cat => cat.value)}
            onSelect={handleSelectCategory}
            placeholder='Category'
            id="category"
          />
          <CustomDropdown
            options={subCategories?.map(sub => sub.value)}
            onSelect={handleSelectSubCategory}
            placeholder='Subcategory'
            id="subCategory"
          />
          <button onClick={filterEquipments}>GO</button>
        </div>

        {user && user.role && user.role.toLowerCase() !== 'admin' && (
          <Link to='/dashboard/add-equipment'>
            <button className='add-btn'>ADD EQUIPMENT</button>
          </Link>
        )}

      </div>
      <div className='table-container'>
        <TableComponent
          columns={equipmentColumns}
          data={filteredEquipments}
          onView={handleView}
          onEdit={handleEdit}
          itemsPerPage={10}
        />
      </div>
    </div>
  );
}

export default EquipmentList;
