import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Addequipment from '../Addequipment';

import { GetEquipmentById, updateEquipment } from '../../../service/equipmentService';
import { toast } from 'sonner';

const EditEquipment = () => {
  const { id } = useParams();
  const [equipmentData, setEquipmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
        const response = await GetEquipmentById(id);
        setEquipmentData(response.data);
        setLoading(false);
    }
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();

  }, []);

  // useEffect(() => {
  //   const fetchEquipment = async () => {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_API_URL}/Equipment/GetEquipmentById?id=${id}`);
  //       setEquipmentData(response.data);
  //     } catch (err) {
  //       setError(err.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchEquipment();
  // }, [id]);

  console.log("edit equipment",equipmentData)

  const handleSave = async (updatedData) => {
    try {
      const response = await updateEquipment(updatedData);

      // Check if the response indicates success
      if (response === 'Equipment updated successfully!') {
        toast.success('Equipment Updated Successfully');
        navigate('/dashboard/equipments-list');
      } else {
        // Handle cases where API doesn't throw but indicates an error
        const errorMessage = response?.message || 'Failed to update equipment';
        setError(errorMessage);
        toast.error(errorMessage);
      }
    } catch (err) {
      // Handle thrown errors
      console.error('Error in handleSave:', err);
      const errorMessage = err.message || 'Unexpected error occurred';
      setError(errorMessage);
      toast.error(errorMessage);
    }
  };



  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <Addequipment equipmentData={equipmentData} onSave={handleSave} />
  );
};

export default EditEquipment;
