import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { GetCustomerById } from '../../../service/customerService';
import Profile from '../../../dashboard/profile/Profile';

function ViewCustomer() {
    const { id } = useParams();
    const [customerData, setCustomerData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
          try {
            if (id) {
            const response = await GetCustomerById(id);
            setCustomerData(response);
            setLoading(false);
        }
          } catch (error) {
            setError(error.message);
            setLoading(false);
          }
        };

        fetchData();

      }, []);

      console.log(customerData)


    //   const handleSave = async (updatedData) => {
    //     try {
    //       await UpdateSubscriptionPlan(updatedData);
    //       navigate('/dashboard/subscription-admin');
    //     } catch (err) {
    //       setError(err.message);
    //     }
    //   };


      if (loading) return <div>Loading...</div>;
      if (error) return <div>Error: {error}</div>;
  return (
    <Profile customerData={customerData} view={true}/>
  )
}

export default ViewCustomer